<template>
    <section class="py-5">
        <div class="p-2 p-xl-5 p-sm-2 p-md-5">
            <form role="form" ref="formSubmit" @submit.prevent="confirmation()" novalidate>
                <div class="card shadow-lg p-5 mx-0 mx-md-5 mx-xl-5">
                    <div class="card-body text-start">
                        <h3 class="text-dark fw-bold mb-3 text-center mb-5">Formulir Upload Bukti Pembayaran</h3>
                        <h6 class="text-dark fw-bold mb-3">Masukkan Identitas Anda</h6>
                        <div class="mb-4">
                            <label for="input1" class="form-label">Nama Lengkap<span
                                    class="text-danger ms-2">*</span></label>
                            <input type="text" class="form-control rounded-2 px-4" placeholder="Masukkan Nama Lengkap"
                                v-model="nama_lengkap" required>
                        </div>
                        <div class="mb-4">
                            <label for="input1" class="form-label">Email / No-Telp<span
                                    class="text-danger ms-2">*</span></label>
                            <input type="text" class="form-control rounded-2 px-4" placeholder="Masukkan Email / No-Telp"
                                v-model="email_nomor_telp" required>
                        </div>
                        <div class="">
                            <label for="input1" class="form-label">Upload Foto KTP<span
                                    class="text-danger ms-2">*</span></label>
                            <div class="file-input-container">
                                <div class="drop-zone text-center" @dragover.prevent="dragOver"
                                    @dragleave.prevent="dragLeave" @drop.prevent="dropFile(0)">
                                    <label for="file-input-3" class="hovers-mouse">
                                        <img class="rounded img-fluid fit-cover"
                                            :src="require('@/assets/img/illustrations/import.svg')" width="28" />
                                        <p v-show="!fileNames[0]">Drag and drop files, or browse</p>
                                    </label>
                                    <input id="file-input-3" type="file" class="visually-hidden"
                                        @change="selectFile(0, $event)" accept="image/png, image/jpeg" required>
                                    <p style="text-align: center; word-wrap: break-word; margin: 0px 0px;"
                                        v-show="fileNames[0]">{{
                                            fileNames[0] }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-check p-5 mx-0 mx-md-5 mx-xl-5">
                    <input class="form-check-input" type="checkbox" v-model="checkImportant" id="flexCheckDefault" required>
                    <label class="form-check-label" for="flexCheckDefault">
                        Dengan mengisi formulir ini, donatur bersedia akan mendapat verifikasi, menerima Bukti Setor,
                        laporan
                        penyaluran, dan info layanan melalui email atau whatsapp
                    </label>
                </div>
                <div class="d-grid px-5 mx-0 mx-md-3 mx-xl-3">
                    <button type="submit" class="btn btn-primary" :class="{'disabled':!checkImportant}">KIRIM</button>
                </div>

                <!-- Modal -->
                <div class="modal fade" id="confirmation" tabindex="0" aria-labelledby="confirmation" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <!-- <div class="modal-header">
                            <h6 class="modal-title">Apakah Anda Yakin ?</h6>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div> -->
                            <div class="modal-body text-center">
                                <i class="fa fa-exclamation text-danger text-gradient mb-3" style="font-size: 50px;"></i>
                                <h6 class="modal-title">Apakah Anda Yakin ?</h6>
                                <h4>Data yang diinput sudah benar</h4>
                                <div class="d-flex justify-content-center">
                                    <button type="button" class="btn btn-sm btn-outline-primary me-3"
                                        data-bs-dismiss="modal">Kembali</button>
                                    <button @click="uploadData()" class="btn btn-sm btn-primary" :disabled="isLoading">
                                        <span class="text-white">Benar</span>
                                        <span v-show="isLoading" class="spinner-border spinner-border-sm ms-2" role="status"
                                            aria-hidden="true"></span>
                                    </button>
                                </div>
                            </div>
                            <!-- <div class="modal-footer justify-content-center">
                            <button type="button" class="btn btn-sm btn-outline-primary"
                                data-bs-dismiss="modal">Kembali</button>
                            <button type="submit" class="btn btn-sm btn-primary">Benar
                            </button>
                        </div> -->
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
</template>
    
<script>
import axios from '@/store/axios';
import bootstrap from '@/vendor/js/bootstrap.js'; // Import Bootstrap JavaScript

export default {
    name: 'HarapanView',
    components: {
    },
    data() {
        return {
            fileNames: [],
            nama_lengkap: '',
            email_nomor_telp: '',
            selectedFiles: [],
            formData: new FormData(),

            checkImportant: false,
            isLoading: false,
        };
    },
    methods: {
        ScrollUp() {
            window.scrollTo(0, 0);
        },
        dragOver(event) {
            event.preventDefault();
            event.currentTarget.classList.add('highlight');
        },
        dragLeave(event) {
            event.preventDefault();
            event.currentTarget.classList.remove('highlight');
        },
        dropFile(index, event) {
            event.preventDefault();
            event.currentTarget.classList.remove('highlight');
            const files = event.dataTransfer.files;
            this.getFileNames(files, index);
        },
        selectFile(index, event) {
            const files = event.target.files;
            this.selectedFiles[index] = event.target.files;
            this.getFileNames(files, index);
        },
        getFileNames(files, index) {
            this.fileNames[index] = [];
            for (let i = 0; i < files.length; i++) {
                this.fileNames[index].push(files[i].name);
            }
            console.log(this.fileNames)
        },
        confirmation() {
            if (this.$refs.formSubmit.checkValidity()) {
                const myModal = bootstrap.Modal.getOrCreateInstance("#confirmation")
                myModal.show();
            } else {
                this.$refs.formSubmit.classList.add('was-validated')
            }
        },
        uploadData() {
            this.isLoading = true;
            var formDatas = new FormData();
            formDatas.append('nama_lengkap', this.nama_lengkap);
            formDatas.append('email_nomor_telp', this.email_nomor_telp);
            formDatas.append('bukti_pembayaran', new Blob(this.selectedFiles[0]));

            // Axios post request with headers and form data
            axios.post('api/bukti_pembayaran_', formDatas)
                .then(() => {
                    const myModal = bootstrap.Modal.getOrCreateInstance("#confirmation")
                    this.isLoading = false

                    //scroll up
                    this.$SwalCenter('success', 'Data Berhasil Disimpan', 'Silahkan Menunggu Beberapa Saat untuk proses validasi data')
                    this.nama_lengkap = '';
                    this.email_nomor_telp = '';
                    this.fileNames= [];
                    this.checkImportant = false;
                    this.$refs.formSubmit.classList.remove('was-validated')
                    myModal.hide();
                })
                .catch(() => {
                    const myModal = bootstrap.Modal.getOrCreateInstance("#confirmation")
                    this.$SwalCenter('error', 'Data Gagal Disimpan', 'Silahkan Menunggu Beberapa Saat, atau Cek Kembali Apakah Form sudah terisi dengan benar.')
                    myModal.hide();
                    this.isLoading = false
                });
        }
    }
}
</script>
    
<style scoped>
.file-input-container {
    position: relative;
}

.drop-zone {
    border: 2px dashed #681EBD;
    padding: 100px;
    border-radius: 10px;
    text-align: center;
}

.file-names {
    margin-top: 10px;
}

.file-names ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.file-names li {
    margin-bottom: 5px;
}

.hovers-mouse {
    cursor: pointer;
    /* Set the cursor to look like it wants to click */
}
</style>