import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './vendor/css/bootstrap.css';
import '@popperjs/core';
import './vendor/js/bootstrap.js';
import './assets/css/untitled.css';
import { SwalCenter } from '@/store/sweetalert';


const app = createApp(App);
// Set up the sweetAlert function globally
app.config.globalProperties.$SwalCenter = SwalCenter;
app.use(store);
app.use(router);
app.mount('#app');