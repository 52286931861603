import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProfilView from '../views/ProfilView.vue'
import HarapanView from '../views/HarapanView.vue'
import HarapankuJNView from '../views/HarapankuJNView.vue'
import KegiatanSosView from '../views/KegiatanSosView.vue'
import PendaftaranView from '../views/PendaftaranView.vue'
import RegistrasiRekView from '../views/RegistrasiRekView.vue'
import RegistrasiNonRekView from '../views/RegistrasiNonRekView.vue'
import HubungiKamiView from '../views/HubungiKamiView.vue'
import DonasiView from '../views/DonasiView.vue'
import FormulirView from '../views/FormulirView.vue'
import DetailHarapankuJN from '../views/DetailHarapankuJNView.vue'
import DetailKegiatanSosial from '../views/DetailKegiatanSosView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // {
  //   path: '/profil',
  //   name: 'profil',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/ProfilView.vue')
  // },
  {
    path: '/profil',
    name: 'profil',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ProfilView
  },
  {
    path: '/harapanku',
    name: 'harapanku',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: HarapanView
  },
  {
    path: '/harapankuJadiNyata',
    name: 'harapankuJadiNyata',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: HarapankuJNView
  },
  {
    path: '/kegiatanSosial',
    name: 'kegiatanSosial',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: KegiatanSosView
  },
  {
    path: '/pendaftaran',
    name: 'pendaftaran',
    component: PendaftaranView
  },
  {
    path: '/registrasiRek',
    name: 'registrasiRek',
    component: RegistrasiRekView
  },
  {
    path: '/registrasiNonRek',
    name: 'registrasiNonRek',
    component: RegistrasiNonRekView
  },
  {
    path: '/hubungiKami',
    name: 'hubungiKami',
    component: HubungiKamiView
  },
  {
    path: '/donasi',
    name: 'donasi',
    component: DonasiView
  },
  {
    path: '/formulirPembayaran',
    name: 'formulirPembayaran',
    component: FormulirView
  },
  {
    path: '/harapankuJadiNyata/detailHarapankuJadiNyata/:id',
    name: 'detailHarapankuJadiNyata',
    component: DetailHarapankuJN
  },
  {
    path: '/kegiatanSosial/detailKegiatanSosial/:id',
    name: 'detailKegiatanSosial',
    component: DetailKegiatanSosial
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // If the user clicks the back or forward button
    if (savedPosition) {
      return savedPosition;
    } else {
      // Scroll to the top of the page
      return { top: 0 };
    }
  },

})

export default router
