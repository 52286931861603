<template>
  <section class="py-5">
    <div class="px-5 py-4 py-xl-5">
      <div class="row gy-4 gy-md-0 align-items-center">
        <div
          class="col-md-7 text-start text-md-start d-flex d-sm-flex d-md-flex justify-content-center align-items-center justify-content-md-start align-items-md-center justify-content-xl-center order-1 order-sm-1 order-md-0 order-xl-0">
          <div class="justify w-lg-75 w-md-75 w-sm-100">
            <h1 class="fw-bolder mb-4" style="color: #371065; font-size: 36px;">Profil Yayasan Urup Mulia Ananda</h1>
            <p class="fw-lighter mb-4 pe-lg-5 pe-md-5 pe-sm-0">
              Berdiri pada tahun 2023, Yayasan Urup Mulia Ananda (YUMA) mengabdi untuk membantu mewujudkan impian dan
              harapan anak-anak pejuang kanker. Mimpi yang mereka miliki akan lebih bernilai nyata dan jauh lebih bermakna
              saat bisa terwujud. Melihat senyum dan kebahagiaan yang terpancar dari sinar matanya saat impian dan
              harapannya menjadi nyata merupakan tujuan utama YUMA.
              <br><br>
              Layaknya cahaya terang yang senantiasa hadir di hati para anak pejuang kanker, YUMA ingin membuat nyata
              impian dan harapan yang mereka miliki. YUMA percaya, impian menjadi nyata dapat mengubah rasa takut mereka
              akan masa depan menjadi sebuah kepercayaan diri yang matang untuk meraih seluruh harapannya.
              <br><br>
              YUMA ingin mengubah kesedihan anak penderita kanker dengan kegembiraan, menghadapi keraguan dengan optimisme
              sehingga tidak ada rasa putus asa dalam diri mereka untuk menjalani hidup. Semua bisa berkontribusi untuk
              membantu mereka meraih impian dan harapannya.
            </p>
          </div>
        </div>
        <div class="col-md-5 order-0 order-sm-0 order-md-1 order-xl-1">
          <div class="text-center">
            <img class="rounded img-fluid fit-cover" :src="require('@/assets/img/illustrations/profil.png')"
              width="500" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pt-5" style="background-color: #9C5CE5; padding-bottom: 120px;">
    <div class="px-5 py-4 py-xl-5">
      <div class="row gy-4 gy-md-0">
        <div class="col-md-6 align-items-start px-lg-5 px-md-5 px-sm-0">
          <div class="justify">
            <h1 class="text-start fw-bolder mb-4 text-white">Visi</h1>
            <p class="text-white fw-lighter mb-4">Yuma ingin membuat impian dan harapan anak-anak pejuang kanker terwujud
              dengan kemampuan dan kapasitasnya. YUMA hadir memberi perhatian dan dukungan bagi mereka yang memiliki
              harapan, mimpi, dan cita-cita untuk masa depannya. Senyum kebahagian mereka menjadi semangat yang nyata
              untuk YUMA terus mengabdi dan berkontribusi bagi Indonesia.
            </p>
          </div>
        </div>
        <div class="col-md-6 align-items-start px-lg-5 px-md-5 px-sm-0">
          <div class="text-start">
            <h1 class="text-start fw-bolder mb-4 text-white">Misi</h1>
            <ol class="p-0 text-white" type="1">
              <li>Mengabdi dalam mewujudkan impian/ keinginan/ harapan anak-anak penderita kanker.</li>
              <li>Melaksanakan kegiatan amal yang berfokus pada dukungan sosial untuk anak-anak Indonesia penderita
                kanker,
                dengan laporan yang akuntabel dan transparan.
              </li>
              <li>Memberikan dukungan moral dan semangat proses penyembuhan kepada anak penderita kanker dan orang tua.
              </li>
              <li>Membantu memberikan pengetahuan seputar pencegahan, penanganan, dan perawatan kanker anak kepada
                masyarakat
                umum.
              </li>
            </ol>
            <p class="text-white fw-lighter mb-4">
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="py-5" style="margin-top: -180px">
    <div class="d-flex justify-content-center mx-lg-5 mx-md-5 mx-sm-0">
      <div class="card text-center shadow rounded-4 py-5 px-4" style="width: 92%; border: none; margin-bottom: 100px;">
        <div class="card-body">
          <div class="mb-5">
            <h2 class="fw-bolder mb-4 mt-4" style="color: #371065;">Legalitas</h2>
            <p class="card-text mb-5">Yayasan Urup Mulia Ananda (YUMA) dalam pendirian dan menjalankan aktivitasnya telah
              dilengkapi dokumen dan perizinan yang sah sesuai ketentuan yang berlaku di Indonesia..</p>
            <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
            <div class="row d-flex justify-content-center align-items-start mt-5">
              <div class="col-xl-4 col-md-4 col-sm-12 box text-start text-sm-start text-md-center text-xl-center">
                <p class="fw-medium text-muted" style="font-size: larger;">SK MENKUMHAM RI NOMOR<br>AHU-0000328.AH.01.05.
                  TAHUN 2023</p>
                <!-- <div>
                  <img class="rounded img-fluid fit-cover shadow" src="/SK_KEMKUMHAM.jpg" width="200" />
                </div> -->
              </div>
              <div class="col-xl-4 col-md-4 col-sm-12 box text-start text-sm-start text-md-center text-xl-center">
                <p class="fw-medium text-muted" style="font-size: larger;">SKT Nomor :<br>S-2755/KT/KPP.080303/2023</p>
                <!-- <div>
                  <img class="rounded img-fluid fit-cover shadow" src="/SKT_KEMENKEU.jpg" width="200" />
                </div> -->
              </div>
            </div>
          </div>
          <!-- {{ --Ruler-- }} -->
          <div>
            <div class="d-flex align-items-center">
              <img class="rounded img-fluid fit-cover" :src="require('@/assets/img/illustrations/spark1.svg')"
                width="30" />
              <hr class="mx-2" width="100%">
              <img class="rounded img-fluid fit-cover" :src="require('@/assets/img/illustrations/spark1.svg')"
                width="30" />
            </div>
          </div>
          <!-- {{ --Ruler-- }} -->
          <div class="mb-5">
            <h2 class="fw-bolder mb-4 mt-4 fs-4" style="color: #371065;">Founder</h2>
            <div class="row d-flex justify-content-center">
              <div class="col-md-3 text-center mb-2">
                <img class="rounded-circle img-fluid fit-cover" :src="require('@/assets/img/fotoProfil/Feny.jpg')"
                  style="min-width: 120px; width: 120px; height: 120px;" />
                <p class="p-0 mt-3 mb-0 mx-0">Feny Djoko Susanto</p>
              </div>
              <div class="col-md-3 text-center mb-2">
                <img class="rounded-circle img-fluid fit-cover"
                  :src="require('@/assets/img/fotoProfil/Sebastian_Ang.jpeg')"
                  style="min-width: 120px; width: 120px; height: 120px;" />
                <p class="p-0 mt-3 mb-0 mx-0">Sebastian Wiraguna Ang</p>
              </div>
            </div>
            <h2 class="fw-bolder mb-4 mt-4 fs-4" style="color: #371065;">Co-Founder</h2>
            <div class="row d-flex justify-content-center">
              <div class="col-md-3 text-center mb-2">
                <img class="rounded-circle img-fluid fit-cover" :src="require('@/assets/img/fotoProfil/Racel.png')"
                  style="min-width: 120px; width: 120px; height: 120px;" />
                <p class="p-0 mt-3 mb-0 mx-0">Rachel Lukman</p>
              </div>
            </div>
          </div>
          <!-- {{ --Ruler-- }} -->
          <div>
            <div class="d-flex align-items-center">
              <img class="rounded img-fluid fit-cover" :src="require('@/assets/img/illustrations/spark1.svg')"
                width="30" />
              <hr class="mx-2" width="100%">
              <img class="rounded img-fluid fit-cover" :src="require('@/assets/img/illustrations/spark1.svg')"
                width="30" />
            </div>
          </div>
          <!-- {{ --Ruler-- }} -->
          <div class="mb-5">
            <h2 class="fw-bolder mb-4 mt-4 fs-4" style="color: #371065;">Pembina</h2>
            <div class="row d-flex justify-content-center">
              <div class="col-md-3 text-center mb-2">
                <img class="rounded-circle img-fluid fit-cover"
                  :src="require('@/assets/img/fotoProfil/Hans_Prawira.jpeg')"
                  style="min-width: 120px; width: 120px; height: 120px;" />
                <p class="p-0 mt-3 mb-0 mx-0">Anggara Hans Prawira</p>
              </div>
            </div>
            <h2 class="fw-bolder mb-4 mt-4 fs-4" style="color: #371065;">Pengawas</h2>
            <div class="row d-flex justify-content-center">
              <div class="col-md-3 text-center mb-2">
                <img class="rounded-circle img-fluid fit-cover" :src="require('@/assets/img/fotoProfil/sri_dewi.jpg')"
                  style="min-width: 120px; width: 120px; height: 120px;" />
                <p class="p-0 mt-3 mb-0 mx-0">Sri Dewi Novani</p>
              </div>
            </div>
            <!-- {{ --Ruler-- }} -->
            <div>
              <div class="d-flex align-items-center">
                <img class="rounded img-fluid fit-cover" :src="require('@/assets/img/illustrations/spark1.svg')"
                  width="30" />
                <hr class="mx-2" width="100%">
                <img class="rounded img-fluid fit-cover" :src="require('@/assets/img/illustrations/spark1.svg')"
                  width="30" />
              </div>
            </div>
            <!-- {{ --Ruler-- }} -->
            <h2 class="fw-bolder mb-4 mt-4 fs-4" style="color: #371065;">Pengurus</h2>
            <div class="row d-flex justify-content-center">
              <div class="col-md-3 text-center mb-2">
                <img class="rounded-circle img-fluid fit-cover" :src="require('@/assets/img/fotoProfil/rani_wijaya.jpg')"
                  style="min-width: 120px; width: 120px; height: 120px;" />
                <p class="p-0 mt-3 mb-0 mx-0">Rani Wijaya</p>
              </div>
              <div class="col-md-3 text-center mb-2">
                <img class="rounded-circle img-fluid fit-cover"
                  :src="require('@/assets/img/fotoProfil/leo_andrianto.jpg')"
                  style="min-width: 120px; width: 120px; height: 120px;" />
                <p class="p-0 mt-3 mb-0 mx-0">Leo Andrianto</p>
              </div>
              <div class="col-md-3 text-center mb-2">
                <img class="rounded-circle img-fluid fit-cover"
                  :src="require('@/assets/img/fotoProfil/natalie_stella.jpg')"
                  style="min-width: 120px; width: 120px; height: 120px;" />
                <p class="p-0 mt-3 mb-0 mx-0">Natalie Stella Oswari</p>
              </div>
            </div>
            <div class="row d-flex justify-content-center mb-4 mt-4">
              <div class="col-md-3 text-center mb-2">
                <img class="rounded-circle img-fluid fit-cover" :src="require('@/assets/img/fotoProfil/priyo_ari.jpg')"
                  style="min-width: 120px; width: 120px; height: 120px;" />
                <p class="p-0 mt-3 mb-0 mx-0">Priyo Ari Handoyo</p>
              </div>
              <div class="col-md-3 text-center mb-2">
                <img class="rounded-circle img-fluid fit-cover"
                  :src="require('@/assets/img/fotoProfil/sendi_febryanto.jpg')"
                  style="min-width: 120px; width: 120px; height: 120px;" />
                <p class="p-0 mt-3 mb-0 mx-0">Sendi Febryanto</p>
              </div>
            </div>
          </div>
          <!-- {{ --Ruler-- }} -->
          <!-- <div>
            <div class="d-flex align-items-center">
              <img class="rounded img-fluid fit-cover" :src="require('@/assets/img/illustrations/spark2.svg')"
                width="60" />
              <hr class="mx-2" width="100%" style="color: transparent;">
              <img class="rounded img-fluid fit-cover" :src="require('@/assets/img/illustrations/spark2.svg')"
                width="60" />
            </div>
          </div> -->
          <!-- {{ --Ruler-- }} -->
          <!-- <div class="mb-5">
            <div class="text-center">
              <img class="img-fluid fit-cover" :src="require('@/assets/img/illustrations/tim_group.png')" width="500" />
              <p class="p-0 mt-3 mb-0 mx-0 fw-bold">Tim Operasional</p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'


export default {
  name: 'ProfilView',
  components: {
  },
  methods: {
    openPdf(file) {
      const fileUrl = file;
      window.open(fileUrl, '_blank');
    }
  }
}
</script>

<style scope>
.box:hover p {
  color: #802FDE !important;
}
</style>
