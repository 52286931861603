<template>
    <section class="py-5">
        <div class="px-5 py-5 py-xl-0">
            <div class="text-center">
                <div v-html="isMobile ? data_show?.data_mobile : data_show?.data" class="mx-0 mx-xl-5 px-0 mx-xl-5">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import artikel1ksImage from "@/assets/img/illustrations/artikel1_1-ks.png";
import artikel11ksImage from "@/assets/img/illustrations/artikel1_2-ks.png";
import artikel21ksImage from "@/assets/img/illustrations/artikel2_2-ks.png";
import artikel22ksImage from "@/assets/img/illustrations/artikel2_1-ks.png";
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
    components: {
    },
    data() {
        return {
            harapanku_jn: [
                {
                    id: 1,
                    data: `<div class="d-flex justify-content-center">
                        <h2 class="mb-5 w-50 fw-bold">Dukungan Semangat Untuk Azrayel Kyran Septiano dari YUMA</h2>
                    </div>
                    <img class="mb-5" src="${artikel1ksImage}"
                        style="width: 50%; border-radius: 10%;" alt="Clipboard Image" />
                    <div class="d-flex justify-content-center justify">
                        <p class="mb-5 w-75">
                            Yayasan Urup Mulia Ananda (YUMA) berkesempatan mengunjungi Azrayel Kyran Septiano yang mengidap penyakit kanker, di kediamannya di Kota Tangerang pada Selasa (8/8/2023).
                            <br /><br />
                            Azrayel kini rutin menjalani proses perawatannya di rumah sakit. Semangat dan dukungan YUMA berikan untuk Azrayel berupa paket buah-buahan dan makanan bergizi guna membantu memenuhi nutrisi kesehatannya selama menjalani perawatannya di rumah maupun selama di rumah sakit.
                        </p>
                    </div>
                    <img class="mb-5" src="${artikel11ksImage}"
                        style="width: 50%; border-radius: 10%;" alt="Clipboard Image" />
                    <div class="d-flex justify-content-center justify">
                        <p class="mb-5 w-75">
                            YUMA juga memberikan bantuan akomodasi Azrayel menuju rumah sakit untuk menjalani cek kesehatannya. Bantuan ini YUMA berikan langsung kepada Azrayel dan kedua orang tuanya.
                            <br /><br />
                            YUMA ingin, semangat dan harapan Azrayel untuk sembuh terus terpancar. YUMA juga berharap bantuan ini dapat memberi manfaat bagi Azrayel.
                        </p>
                    </div>
                    `,
                    data_mobile: `<div class="d-flex justify-content-center">
                        <h2 class="mb-5 fw-bold">Dukungan Semangat Untuk Azrayel Kyran Septiano dari YUMA</h2>
                    </div>
                    <img class="mb-5" src="${artikel1ksImage}"
                        style="width: 100%; border-radius: 10%;" alt="Clipboard Image" />
                    <div class="d-flex justify-content-center justify">
                        <p class="mb-5">
                            Yayasan Urup Mulia Ananda (YUMA) berkesempatan mengunjungi Azrayel Kyran Septiano yang mengidap penyakit kanker, di kediamannya di Kota Tangerang pada Selasa (8/8/2023).
                            <br /><br />
                            Azrayel kini rutin menjalani proses perawatannya di rumah sakit. Semangat dan dukungan YUMA berikan untuk Azrayel berupa paket buah-buahan dan makanan bergizi guna membantu memenuhi nutrisi kesehatannya selama menjalani perawatannya di rumah maupun selama di rumah sakit.
                        </p>
                    </div>
                    <img class="mb-5" src="${artikel11ksImage}"
                        style="width: 100%; border-radius: 10%;" alt="Clipboard Image" />
                    <div class="d-flex justify-content-center justify">
                        <p class="mb-5">
                            YUMA juga memberikan bantuan akomodasi Azrayel menuju rumah sakit untuk menjalani cek kesehatannya. Bantuan ini YUMA berikan langsung kepada Azrayel dan kedua orang tuanya.
                            <br /><br />
                            YUMA ingin, semangat dan harapan Azrayel untuk sembuh terus terpancar. YUMA juga berharap bantuan ini dapat memberi manfaat bagi Azrayel.
                        </p>
                    </div>
                    `,
                },
                {
                    id: 2,
                    data: `<div class="d-flex justify-content-center">
                        <h2 class="mb-5 w-50 fw-bold">Bantuan Keperluan Perawatan Untuk Rezfan Sultan Al Fatih</h2>
                    </div>
                    <img class="mb-5" src="${artikel21ksImage}"
                        style="width: 50%; border-radius: 10%;" alt="Clipboard Image" />
                    <div class="d-flex justify-content-center justify">
                        <p class="mb-5 w-75">
                            Semangat untuk membantu dan memberi kebahagian bagi anak pejuang kanker, Yayasan Urup Mulia Ananda (YUMA) bersilaturahmi mengunjungi dan memberikan bantuan keperluan perawatan untuk Rezfan Sultan Al Fatih di kediamannya, Koja Jakarta Utara.
                            <br /><br />
                            YUMA memberikan dukungan bantuan bagi proses perawatan Rezfan yang saat ini terbaring lemah akibat sakit yang dideritanya. Satu set lengkap tabung oxygen, Nebulizer, pampers, underpad, dan susu pertumbuhan YUMA berikan untuk memenuhi gizi yang diperlukan Rezfan untuk proses perawatannya.
                            <br /><br />
                            Bantuan diserahkan langsung pengurus YUMA kepada kedua orang tua Rezfan, pada Kamis (28/9/2023). 
                            <br /><br />
                            Melalui bantuan ini, YUMA berharap bukan hanya dapat meringankan kebutuhan yang diperlukan Rezfan, namun juga memberi dukungan semangat bagi Rezfan dan orang tuanya yang menantikan kesembuhan Rezfan.
                            <br /><br />
                            Sebelumnya orang tua Rezfan bercerita, anaknya mengidap kanker sejak Februari 2023. Sebelum mengidap kanker, Rezfan merupakan anak yang aktif dan normal seperti teman seusianya. 
                        </p>
                    </div>
                    <img class="mb-5" src="${artikel22ksImage}"
                        style="width: 50%; border-radius: 10%;" alt="Clipboard Image" />
                    <div class="d-flex justify-content-center justify">
                        <p class="mb-5 w-75">
                            Sang ibu bercerita, Rezfan memiliki cita-cita sebagai prajurit TNI (tentara nasional Indonesia), serta ingin membahagiakan kedua orang tuanya.
                            <br /><br />
                            Saat ini hanya doa dan semangat dari kedua orang tua Rezfan untuk merawat serta menanti kesembuhan putra semata wayangnya tersebut.
                        </p>
                    </div>
                    `,
                    data_mobile: `<div class="d-flex justify-content-center">
                        <h2 class="mb-5 fw-bold">Bantuan Keperluan Perawatan Untuk Rezfan Sultan Al Fatih</h2>
                    </div>
                    <img class="mb-5" src="${artikel21ksImage}"
                        style="width: 100%; border-radius: 10%;" alt="Clipboard Image" />
                    <div class="d-flex justify-content-center justify">
                        <p class="mb-5">
                            Semangat untuk membantu dan memberi kebahagian bagi anak pejuang kanker, Yayasan Urup Mulia Ananda (YUMA) bersilaturahmi mengunjungi dan memberikan bantuan keperluan perawatan untuk Rezfan Sultan Al Fatih di kediamannya, Koja Jakarta Utara.
                            <br /><br />
                            YUMA memberikan dukungan bantuan bagi proses perawatan Rezfan yang saat ini terbaring lemah akibat sakit yang dideritanya. Satu set lengkap tabung oxygen, Nebulizer, pampers, underpad, dan susu pertumbuhan YUMA berikan untuk memenuhi gizi yang diperlukan Rezfan untuk proses perawatannya.
                            <br /><br />
                            Bantuan diserahkan langsung pengurus YUMA kepada kedua orang tua Rezfan, pada Kamis (28/9/2023). 
                            <br /><br />
                            Melalui bantuan ini, YUMA berharap bukan hanya dapat meringankan kebutuhan yang diperlukan Rezfan, namun juga memberi dukungan semangat bagi Rezfan dan orang tuanya yang menantikan kesembuhan Rezfan.
                            <br /><br />
                            Sebelumnya orang tua Rezfan bercerita, anaknya mengidap kanker sejak Februari 2023. Sebelum mengidap kanker, Rezfan merupakan anak yang aktif dan normal seperti teman seusianya. 
                        </p>
                    </div>
                    <img class="mb-5" src="${artikel22ksImage}"
                        style="width: 100%; border-radius: 10%;" alt="Clipboard Image" />
                    <div class="d-flex justify-content-center justify">
                        <p class="mb-5">
                            Sang ibu bercerita, Rezfan memiliki cita-cita sebagai prajurit TNI (tentara nasional Indonesia), serta ingin membahagiakan kedua orang tuanya.
                            <br /><br />
                            Saat ini hanya doa dan semangat dari kedua orang tua Rezfan untuk merawat serta menanti kesembuhan putra semata wayangnya tersebut.
                        </p>
                    </div>
                    `,
                },
            ],
            data_show: {},
            screenWidth: window.innerWidth, // Initialize with the current screen width
            isMobile: false,
        }
    },
    mounted() {
        // Attach a window resize event listener to update the screenWidth data property
        window.addEventListener('resize', this.handleResize);
        if (this.screenWidth <= 610) {
            this.isMobile = true
        } else {
            this.isMobile = false
        }

        //find data
        const targetId = this.$route.params.id; // The id you want to find
        const foundItem = this.harapanku_jn.find(item => item.id == targetId);
        this.data_show = foundItem;
    },
    watch: {
        screenWidth(newValue) {
            if (newValue <= 610) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        }
    },
    methods: {
        scrollToRight() {
            const scrollableDiv = this.$refs.scrollableDiv;
            scrollableDiv.scrollBy({ left: 300, behavior: 'smooth' }); // Adjust the scroll value as needed
        },
        scrollToLeft() {
            const scrollableDiv = this.$refs.scrollableDiv;
            scrollableDiv.scrollBy({ left: -300, behavior: 'smooth' }); // Adjust the scroll value as needed
        },
        handleResize() {
            // Update the screenWidth data property when the window is resized
            this.screenWidth = window.innerWidth;
        }
    },
    unmounted() {
        // Remove the window resize event listener when the component is destroyed
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>
<style scoped>
.btn-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 14px;
    color: #802fde;
}

.btn-circle:hover {
    color: white;
    background-color: #802fde;
}

.scrollable-div {
    overflow-x: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
}

/* Hide the scrollbar for modern browsers */
.scrollable-div::-webkit-scrollbar {
    width: 0;
    height: 0;
}
</style>
