<template>
    <header>
        <div class="row m-0" style="padding: 115px 119px; background-repeat: no-repeat; background-size: cover;"
            :style="{ backgroundImage: 'url(' + imageUrl + ')' }">
            <div class="col-md-3 mb-3 p-0">
                <h2 class="fw-bold mb-4 text-white" style="line-height: 1.5;">Prosedur berdonasi secara online dengan mudah
                </h2>
                <div class="rounded-5"
                    style="border: none; height: 4px; width: 35%; background-color: white; color: white;"></div>
            </div>
            <div class="col-md-4 mb-3 px-0 px-sm-0 px-md-5 px-xl-5 text-start">
                <span class="badge rounded-circle text-light mb-3" style="background-color: #501791; padding: 14px;">
                    <p class="text-white fs-6 m-0" style="padding: 0px 6px;">1</p>
                </span>
                <h5 class="text-white fw-medium">Melakukan transfer</h5>
                <div class="rounded-5 mb-3"
                    style="border: none; height: 2px; width: 15%; background-color: #501791; color: #501791;"></div>
                <p class="fw-lighter text-white m-0">Anda bisa langsung berdonasi dengan melakukan transfer melalui rekening
                    yang sudah kami sediakan.</p>
            </div>
            <div class="col-md-4 mb-3 px-0 px-sm-0 px-md-5 px-xl-5 text-start">
                <span class="badge rounded-circle text-light mb-3" style="background-color: #501791; padding: 14px;">
                    <p class="text-white fs-6 m-0" style="padding: 0px 4px;">2</p>
                </span>
                <h5 class="text-white fw-medium">Upload bukti transfer</h5>
                <div class="rounded-5 mb-3"
                    style="border: none; height: 2px; width: 15%; background-color: #501791; color: #501791;"></div>
                <p class="fw-lighter text-white m-0">Setelah berhasil transfer, Anda diwajibkan untuk melakukan bukti
                    pembayaran melalui formulir yang telah kami sediakan</p>
            </div>
        </div>
    </header>
    <section class="py-5">
        <div class="p-2 p-xl-5 p-sm-2 p-md-5">
            <div class="card shadow-lg p-5 mx-0 mx-md-5 mx-xl-5"
                style="background-repeat: no-repeat; background-size: 500px; background-position: center;"
                :style="{ backgroundImage: 'url(' + imageUrls + ')' }">
                <div class="card-body text-center">
                    <h4 class="text-primary fw-bold mb-4">Donasi YUMA</h4>
                    <h6 class="text-primary fw-bold mb-4">Donasi amal Yayasan Urup Mulia Ananda</h6>
                    <h6 class="fw-bold mb-5">Anda bisa berdonasi dengan melakukan transfer ke Rekening dibawah
                        ini</h6>
                    <p class="text-muted fw-medium mb-2">Bank</p>
                    <p class="fw-bold mb-4" style="color: #371065;">MANDIRI</p>
                    <p class="text-muted fw-medium mb-2">Nomor Rekening</p>
                    <p class="fw-bold mb-4" style="color: #371065;">155 00 8181919 8</p>
                    <p class="text-muted fw-medium mb-2">Atas Nama</p>
                    <p class="fw-bold mb-4" style="color: #371065;">Yayasan Urup Mulia Ananda</p>
                </div>
            </div>
            <p class="fw-medium p-5 mx-0 mx-md-2 mx-xl-2">Silahkan upload bukti transfer apabila Anda sudah berhasil
                melakukan pembayaran <a class="text-dark fw-bold" href="#"
                    @click="$router.push('/formulirPembayaran')"><u>Disini</u></a></p>
        </div>
    </section>
</template>
    
<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: 'HarapanView',
    components: {
    },
    data() {
        return {
            imageName: 'bg_prosedur.png',
            imageDonasi: 'bg_donasi.png',
        };
    },
    methods: {

    },
    computed: {
        imageUrl() {
            return require(`@/assets/img/illustrations/${this.imageName}`);
        },
        imageUrls() {
            return require(`@/assets/img/illustrations/${this.imageDonasi}`);
        },
    }
}
</script>
    