<template>
    <footer class="footer">
        <div class="px-5">
            <div class="row align-items-center">
                <div class="col-md-6 mb-5 mb-lg-0 justify">
                    <img :src="require('@/assets/img/icon/Logo_YUMA_white.svg')" alt="Clipboard Image" width="300"
                        class="mb-5" />
                    <p class="mb-5">Jl. Raya Serpong KM 7 Komplek Pergudangan, RT 004 RW 001 Multiguna A No. 3 Serpong
                        Utara, Pakualam, Kota Tangerang Selatan 15335.</p>
                    <ul class="list-inline">
                        <li class="list-inline-item me-3"><a class="text-center text-white" role="button"
                                href="https://www.youtube.com/channel/UCAIElAoleDip_Te8hC-kePw"><i class="fa fa-youtube fa-fw fs-4"></i></a></li>
                        <li class="list-inline-item me-3"><a class="text-center text-white" role="button"
                                href="https://www.instagram.com/yuma_or.id/"><i
                                    class="fa fa-instagram fa-fw fs-4"></i></a></li>
                        <li class="list-inline-item me-3"><a class="text-center text-white" role="button" href="#"
                                @click="openGmail()"><i class="fas fa-envelope fa-fw fs-4"></i></a></li>
                    </ul>
                </div>
                <div class="col-md-6 footer-aligned">
                    <!-- <h4 class="text-uppercase mb-4">About Freelancer</h4>
          <p class="lead mb-0"><span>Freelance is a free to use, open source Bootstrap theme.</span></p> -->
                    <div class="mb-3">
                        <a class="nav-link mx-1" :class="getRoute() === '' ? 'active' : ''" href="#"
                            @click="$router.push('/')" :data-bs-toggle="isMobile ? 'collapse' : null"
                            :data-bs-target="isMobile ? '#navcol-1' : null">Beranda</a>
                    </div>
                    <div class="mb-3">
                        <a class="nav-link mx-1" :class="getRoute() === 'profil' ? 'active' : ''" href="#"
                            @click="$router.push('/profil')" :data-bs-toggle="isMobile ? 'collapse' : null"
                            :data-bs-target="isMobile ? '#navcol-1' : null">Profil</a>
                    </div>
                    <div class="mb-3">
                        <a class="nav-link mx-1" :class="getRoute() === 'harapanku' ? 'active' : ''" href="#"
                            @click="$router.push('/harapanku')" :data-bs-toggle="isMobile ? 'collapse' : null"
                            :data-bs-target="isMobile ? '#navcol-1' : null">Harapanku</a>
                    </div>
                    <div class="mb-3">
                        <a class="nav-link mx-1" :class="getRoute() === 'harapanku' ? 'active' : ''" href="#"
                            @click="$router.push('/harapankuJadiNyata')" :data-bs-toggle="isMobile ? 'collapse' : null"
                            :data-bs-target="isMobile ? '#navcol-1' : null">Harapanku Jadi Nyata</a>
                    </div>
                    <div class="mb-3">
                        <a class="nav-link mx-1" :class="getRoute() === 'harapanku' ? 'active' : ''" href="#"
                            @click="$router.push('/kegiatanSosial')" :data-bs-toggle="isMobile ? 'collapse' : null"
                            :data-bs-target="isMobile ? '#navcol-1' : null">Kegiatan Sosial</a>
                    </div>
                    <div class="mb-3">
                        <a class="nav-link mx-1" :class="getRoute() === 'pendaftaran' ? 'active' : ''" href="#"
                            @click="$router.push('/pendaftaran')" :data-bs-toggle="isMobile ? 'collapse' : null"
                            :data-bs-target="isMobile ? '#navcol-1' : null">Pendaftaran</a>
                    </div>
                    <div class="mb-3">
                        <a class="nav-link mx-1" :class="getRoute() === 'hubungiKami' ? 'active' : ''" href="#"
                            @click="$router.push('/hubungiKami')" :data-bs-toggle="isMobile ? 'collapse' : null"
                            :data-bs-target="isMobile ? '#navcol-1' : null">Hubungi Kami</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'HomeView',
    components: {
    },
    methods: {
        openGmail() {
            // Replace these variables with the recipient's email and subject
            const recipientEmail = 'admin@yuma.or.id';
            const subject = 'Pesan dan Saran';

            // Compose the mailto link
            const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}`;

            // Open the link in a new tab/window
            window.open(mailtoLink, '_blank');
        },
        getRoute() {
            const routeArr = this.$route.path.split("/");
            return routeArr[1];
        },
    }
}
</script>