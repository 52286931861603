<template>
  <header>
    <div id="carouselExampleCaptions" class="carousel slide carousel-fade" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="3000">
          <img :src="require('@/assets/img/illustrations/SplashScreen1.png')" class="d-block w-100" alt="..."
            style="filter: brightness(50%);">
          <div class="carousel-text">
            <h1 class="text-white fw-bold mb-3">Yayasan Urup Mulia Ananda</h1>
            <p class="text-white mb-3 fw-light" :class="{ 'fs-5': !isMobile }">Hadir untuk mengabdi, membuat impian dan
              harapan anak-anak pejuang
              kanker menjadi
              nyata. YUMA hadir memberi perhatian dan dukungan bagi mereka yang memiliki harapan, mimpi, dan cita-cita
              untuk masa depan.</p>

          </div>
        </div>
        <div class="carousel-item" data-bs-interval="3000">
          <img :src="require('@/assets/img/illustrations/SplashScreen2.png')" class="d-block w-100" alt="..."
            style="filter: brightness(50%);">
          <div class="carousel-text">
            <h1 class="text-white fw-bold mb-3">Yayasan Urup Mulia Ananda</h1>
            <p class="text-white mb-3 fw-light" :class="{ 'fs-5': !isMobile }">Hadir untuk mengabdi, membuat impian dan
              harapan anak-anak pejuang
              kanker menjadi
              nyata. YUMA hadir memberi perhatian dan dukungan bagi mereka yang memiliki harapan, mimpi, dan cita-cita
              untuk masa depan.</p>

          </div>
        </div>
        <div class="carousel-item" data-bs-interval="3000">
          <img :src="require('@/assets/img/illustrations/SplashScreen3.png')" class="d-block w-100" alt="..."
            style="filter: brightness(50%);">
          <div class="carousel-text">
            <h1 class="text-white fw-bold mb-3">Yayasan Urup Mulia Ananda</h1>
            <p class="text-white mb-3 fw-light" :class="{ 'fs-5': !isMobile }">Hadir untuk mengabdi, membuat impian dan
              harapan anak-anak pejuang
              kanker menjadi
              nyata. YUMA hadir memberi perhatian dan dukungan bagi mereka yang memiliki harapan, mimpi, dan cita-cita
              untuk masa depan.</p>

          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </header>
  <section class="py-5">
    <div class="px-5 py-4 py-xl-5">
      <div class="row gy-4 gy-md-0">
        <div
          class="col-md-6 d-flex align-items-center justify-content-center justify-content-sm-center justify-content-md-start justify-content-xl-start ps-0 ps-sm-0 ps-md-5 ps-xl-5">
          <img class="rounded img-fluid fit-cover" :src="require('@/assets/img/illustrations/Vector.png')" width="500" />
        </div>
        <div
          class="col-md-6 text-start text-md-start d-flex d-sm-flex d-md-flex justify-content-center align-items-center justify-content-md-start align-items-md-center justify-content-xl-center">
          <div class="justify">
            <h1 class="fw-bold mb-4">Tentang <span class="text-primary">YUMA</span></h1>
            <p class="text-muted fw-lighter mb-4">Berdiri tahun 2023, Yayasan Urup Mulia Ananda (YUMA) mengabdi untuk
              membantu mewujudkan impian/ keinginan/ harapan anak-anak penderita atau pejuang penyakit kanker. Mimpi
              mereka memiliki nilai nyata, dan itu jauh lebih bermakna saat bisa tercapai. Mewujudkan impian/ keinginan
              mereka dapat memberikan kekuatan mental dan keberanian untuk melakukan perawatan medis hingga sembuh.</p>
            <a class="btn btn-primary rounded-2 py-2 px-4" href="/profil">Lihat Detail</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="py-5">
    <div class="px-5 py-4 py-xl-5">
      <div class="row gy-4 gy-md-0">
        <div
          class="col-md-6 text-end text-md-end d-flex d-sm-flex d-md-flex justify-content-center align-items-center justify-content-md-start align-items-md-center justify-content-xl-center order-1 order-sm-1 order-md-0 order-xl-0">
          <div class="justify ms-auto">
            <h1 class="text-start fw-bold mb-4">Kebaikan <span class="text-primary">YUMA</span></h1>
            <p class="text-muted fw-lighter mb-4 ">Kanker yang diderita oleh anak-anak, pada anak berbeda dengan yang
              diderita oleh orang dewasa. Mulai dari cara penanganan hingga perawatannya yang akan memakan waktu hingga
              merenggut aktivitas bermain mereka. Hal ini akan membuat ruang dan gerak mereka terbatas untuk bisa meraih
              impian dan harapan di masa depan.
              <br><br>
              Semangat dan keceriaan harus terus terpancar dari senyum mereka. YUMA akan menjadi bagian dari anak-anak
              pejuang kanker meraih keinginannya tersebut. Melalui program Harapanku, YUMA berusaha menuangkan semua
              impian dan harapan mereka agar terwujud.
            </p>
          </div>
        </div>
        <div
          class="col-md-6 d-flex align-items-center justify-content-center justify-content-sm-center justify-content-md-end justify-content-xl-end pe-0 pe-sm-0 pe-md-5 pe-xl-5 order-0 order-sm-0 order-md-1 order-xl-1">
          <img class="rounded img-fluid fit-cover" :src="require('@/assets/img/illustrations/Vector1.png')" width="500" />
        </div>
      </div>
    </div>
  </section>
  <!-- <section class="py-5">
    <div class="text-center">
      <h1 class="fw-bold">Bentuk Kegiatan <span class="text-primary">YUMA</span></h1>
    </div>
    <div class="container px-5">
      <div class="text-end mb-4"><a class="fw-bold" href="javacript:;">Lihat Lainnya</a></div>
      <div class="row gy-4 gy-md-0 d-flex align-items-center">
        <div class="col-md-4 d-flex justify-content-center">
          <div class="card rounded-3" style="width: 18rem;">
            <img :src="require('@/assets/img/illustrations/clipboard-image.png')" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title fw-bold">Lorem Ipsum</h5>
              <p class="card-text" style="font-size:small;">An interesting implication of the 2007 study concerns the use
                of hand sanitizers by
                observant Muslims. Alcohol is forbidden (haram) to Muslims. Since the alcohol in hand sanitizer is
                absorbed into the blood, does that make these hand sanitizers haram?</p>
            </div>
          </div>
        </div>
        <div class="col-md-4 d-flex justify-content-center">
          <div class="card rounded-3" style="width: 18rem;">
            <img :src="require('@/assets/img/illustrations/clipboard-image.png')" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title fw-bold">Lorem Ipsum</h5>
              <p class="card-text" style="font-size:small;">An interesting implication of the 2007 study concerns the use
                of hand sanitizers by
                observant Muslims. Alcohol is forbidden (haram) to Muslims. Since the alcohol in hand sanitizer is
                absorbed into the blood, does that make these hand sanitizers haram?</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card rounded-3 mb-3" style="max-width: 540px;">
            <div class="row g-0">
              <div class="col-md-4">
                <img src="" class="img-fluid rounded-start" alt="">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">An interesting implication</h5>
                  <p class="card-text">Lorem Ipsum Lorem Ipsum Lorem</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card rounded-3 mb-3" style="max-width: 540px;">
            <div class="row g-0">
              <div class="col-md-4">
                <img src="" class="img-fluid rounded-start" alt="">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">An interesting implication</h5>
                  <p class="card-text">Lorem Ipsum Lorem Ipsum Lorem</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card rounded-3 mb-3" style="max-width: 540px;">
            <div class="row g-0">
              <div class="col-md-4">
                <img src="" class="img-fluid rounded-start" alt="">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">An interesting implication</h5>
                  <p class="card-text">Lorem Ipsum Lorem Ipsum Lorem</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <section class="py-5">
    <div class="px-5 py-4 py-xl-5 fit-img text-center custom-background">
      <h1 class="fw-semibold text-white mb-4 custom-background-content">Kontak YUMA</h1>
      <div class="row custom-background-content">
        <p class="text-white text-lg-end text-md-end text-sm-end col-md-6 mb-4 pe-0 pe-sm-0 pe-md-5 pe-xl-5"><i
            class="fas fa-envelope pe-1"></i>admin@yuma.or.id</p>
        <p class="text-white text-lg-start text-md-start text-sm-start col-md-6 mb-4 ps-0 ps-sm-0 ps-md-5 ps-xl-5 "><i
            class="fab fa-instagram-square pe-1"></i>Yayasan Urup Mulia Ananda</p>
      </div>
      <p class="text-white text-center mb-4 custom-background-content"><i class="fab fa-youtube pe-1"></i>YUMA Official</p>
      <router-link class="btn btn-primary px-5 py-2 my-4 rounded-2 custom-background-content" :to="'/hubungiKami'">Hubungi Kami</router-link>
    </div>
  </section>
  <!-- <section class="mt-5 py-5" style="margin-bottom: 120px;">
    <div class="text-center">
      <h1 class="fw-bold mb-5">Mitra <span class="text-primary">YUMA</span></h1>
      <div class="d-flex justify-content-center">
        <div class="w-50">
          <div class="row">
            <div class="col-md-3 mb-4">
              <img :src="require('@/assets/img/brands/clipboard-image.png')" alt="Clipboard Image" width="120" />
            </div>
            <div class="col-md-3 mb-4">
              <img :src="require('@/assets/img/brands/clipboard-image.png')" alt="Clipboard Image" width="120" />
            </div>
            <div class="col-md-3 mb-4">
              <img :src="require('@/assets/img/brands/clipboard-image.png')" alt="Clipboard Image" width="120" />
            </div>
            <div class="col-md-3 mb-4">
              <img :src="require('@/assets/img/brands/clipboard-image.png')" alt="Clipboard Image" width="120" />
            </div>
            <div class="col-md-3 mb-4">
              <img :src="require('@/assets/img/brands/clipboard-image.png')" alt="Clipboard Image" width="120" />
            </div>
            <div class="col-md-3 mb-4">
              <img :src="require('@/assets/img/brands/clipboard-image.png')" alt="Clipboard Image" width="120" />
            </div>
            <div class="col-md-3 mb-4">
              <img :src="require('@/assets/img/brands/clipboard-image.png')" alt="Clipboard Image" width="120" />
            </div>
            <div class="col-md-3 mb-4">
              <img :src="require('@/assets/img/brands/clipboard-image.png')" alt="Clipboard Image" width="120" />
            </div>
            <div class="col-md-3 mb-4">
              <img :src="require('@/assets/img/brands/clipboard-image.png')" alt="Clipboard Image" width="120" />
            </div>
            <div class="col-md-3 mb-4">
              <img :src="require('@/assets/img/brands/clipboard-image.png')" alt="Clipboard Image" width="120" />
            </div>
            <div class="col-md-3 mb-4">
              <img :src="require('@/assets/img/brands/clipboard-image.png')" alt="Clipboard Image" width="120" />
            </div>
            <div class="col-md-3 mb-4">
              <img :src="require('@/assets/img/brands/clipboard-image.png')" alt="Clipboard Image" width="120" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import bootstrap from '@/vendor/js/bootstrap';

export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
      screenWidth: window.innerWidth, // Initialize with the current screen width
      isMobile: true
    };
  },
  mounted() {
    // Attach a window resize event listener to update the screenWidth data property
    window.addEventListener('resize', this.handleResize);
  },
  watch: {
    screenWidth(newValue) {
      if (newValue <= 610) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    }
  },
  methods: {
    triggerButtonClick() {
      const prevButton = document.querySelector('.carousel-control-next');
      prevButton.click();
      console.log(this.screenWidth)
    },
    handleResize() {
      // Update the screenWidth data property when the window is resized
      this.screenWidth = window.innerWidth;
    }
  },
  unmounted() {
    // Remove the window resize event listener when the component is destroyed
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>
<style>
.custom-background {
  position: relative;
  /* Create a positioning context */
  background-image: url('@/assets/img/illustrations/Rectangle_55.png');
  background-size: cover;
  background-position: center 25%;
}

.custom-background::before {
  content: '';
  background: rgba(0, 0, 0, 0.5);
  /* Adjust the background color and opacity as needed */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  /* Place it behind the content */
}

/* Style for the content inside the container */
.custom-background-content {
  position: relative;
  /* So you can control the stacking context */
  z-index: 1;
  /* Place it above the background overlay */
}</style>
