<template>
    <section class="py-5" v-if="form1 == true">
        <form role="form" ref="formSubmit1" @submit.prevent="formSubmit1()" novalidate>
            <div class="d-flex align-items-center mb-4 px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1">
                <h2 class="fw-bold me-auto p-2">Biodata Anak</h2>
                <p class="m-0 p-2">Halaman 1 dari 3</p>
            </div>
            <div class="px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div style="padding: 0px 12px;">
                    <label for="input1" class="form-label">Nama Lengkap<span class="text-danger ms-2">*</span></label>
                    <input type="text" class="form-control rounded-2 px-4" placeholder="Masukkan Nama Lengkap"
                        v-model="nama_lengkap" required>
                </div>
            </div>
            <div class="row px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div class="col-md-6">
                    <label for="input1" class="form-label">Jenis Kelamin<span class="text-danger ms-2">*</span></label>
                    <select class="form-select rounded-2 px-4" aria-label="Default select example" v-model="jenis_kelamin"
                        required>
                        <option selected value="" disabled>Pilih Jenis Kelamin</option>
                        <option value="Laki-Laki">Laki - Laki</option>
                        <option value="Perempuan">Perempuan</option>
                    </select>
                </div>
            </div>
            <div class="row px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div class="col-md-6 mb-2">
                    <label for="input1" class="form-label">Tempat Lahir<span class="text-danger ms-2">*</span></label>
                    <input type="text" class="form-control rounded-2 px-4" placeholder="Masukkan Tempat lahir"
                        v-model="tempat_lahir" required>
                </div>
                <div class="col-md-6 mb-2">
                    <label for="input1" class="form-label">Tanggal Lahir<span class="text-danger ms-2">*</span></label>
                    <input type="date" class="form-control rounded-2 px-4" placeholder="Masukkan Tanggal lahir"
                        v-model="tanggal_lahir" required>
                </div>
            </div>
            <div class="px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div style="padding: 0px 12px;">
                    <label for="input1" class="form-label">Alamat Lengkap<span class="text-danger ms-2">*</span></label>
                    <textarea class="form-control rounded-2 px-4" placeholder="Masukkan nama jalan" rows="3"
                        v-model="alamat_lengkap" required></textarea>
                </div>
            </div>
            <div class="row px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div class="col-md-6 mb-2">
                    <label for="input1" class="form-label">Kecamatan<span class="text-danger ms-2">*</span></label>
                    <input type="text" class="form-control rounded-2 px-4" placeholder="Kecamatan" v-model="kecamatan"
                        required>
                </div>
                <div class="col-md-6 mb-2">
                    <label for="input1" class="form-label">Kota/ Kabupaten<span class="text-danger ms-2">*</span></label>
                    <input type="text" class="form-control rounded-2 px-4" placeholder="Kota/ Kabupaten"
                        v-model="kota_kabupaten" required>
                </div>
            </div>
            <div class="row px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div class="col-md-6 mb-2">
                    <label for="input1" class="form-label">Provinsi<span class="text-danger ms-2">*</span></label>
                    <input type="text" class="form-control rounded-2 px-4" placeholder="Provinsi" v-model="provinsi"
                        required>
                </div>
                <div class="col-md-6 mb-2">
                    <label for="input1" class="form-label">Kode Pos<span class="text-danger ms-2">*</span></label>
                    <input type="number" class="form-control rounded-2 px-4" placeholder="Kode Pos" v-model="kode_post"
                        required>
                </div>
            </div>
            <div class="row px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div class="col-md-6 mb-2">
                    <label for="input1" class="form-label">Sekolah<span class="text-danger ms-2">*</span></label>
                    <input type="text" class="form-control rounded-2 px-4" placeholder="Sekolah" v-model="sekolah" required>
                </div>
                <div class="col-md-6 mb-2">
                    <label for="input1" class="form-label">Kelas<span class="text-danger ms-2">*</span></label>
                    <input type="number" class="form-control rounded-2 px-4" placeholder="Kelas" v-model="kelas" required>
                </div>
            </div>
            <div class="row px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div class="col-md-6">
                    <label for="input1" class="form-label">Kanker yang diderita<span
                            class="text-danger ms-2">*</span></label>
                    <input type="text" class="form-control rounded-2 px-4" placeholder="Kanker yang Diderita"
                        v-model="jenis_kanker" required>
                </div>
                <div class="col-md-6 mb-2 py-0">
                    <label for="input1" class="form-label">Stadium<span class="text-danger ms-2">*</span></label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio0"
                                value="0" v-model="selectedStadium" required>
                            <label class="form-check-label" for="inlineRadio0">0</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                                value="1" v-model="selectedStadium" required>
                            <label class="form-check-label" for="inlineRadio1">1</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                                value="2" v-model="selectedStadium" required>
                            <label class="form-check-label" for="inlineRadio2">2</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3"
                                value="3" v-model="selectedStadium" required>
                            <label class="form-check-label" for="inlineRadio3">3</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4"
                                value="4" v-model="selectedStadium" required>
                            <label class="form-check-label" for="inlineRadio4">4</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-4 px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1">
                <div class="col-md-6 text-start">
                    <h6 class=""><span class="text-danger">* </span>Tanda Bintang Wajib Diisi</h6>
                </div>
                <div class="col-md-6 text-end">
                    <button type="submit" class="btn btn-sm btn-danger px-5 rounded-2">LANJUT</button>
                </div>
            </div>
        </form>
    </section>
    <section class="py-5" v-else-if="form2 == true">
        <form role="form" ref="formSubmit2" @submit.prevent="formSubmit2()" novalidate>
            <div class="d-flex align-items-center mb-4 px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1">
                <h2 class="fw-bold me-auto p-2">Biodata Rekomendasi Orang Tua</h2>
                <p class="m-0 p-2">Halaman 2 dari 3</p>
            </div>
            <div class="px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div style="padding: 0px 12px;">
                    <label for="input1" class="form-label">Nama Lengkap<span class="text-danger ms-2">*</span></label>
                    <input type="text" class="form-control rounded-2 px-4" placeholder="Masukkan Nama Lengkap"
                        v-model="nama_lengkap_ortu" required>
                </div>
            </div>
            <div class="px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div style="padding: 0px 12px;">
                    <label for="input1" class="form-label">Nomor Telepon/WA<span class="text-danger ms-2">*</span></label>
                    <input type="text" pattern="[0-9]*" class="form-control rounded-2 px-4"
                        placeholder="Masukkan Nomor Telepon/WA" v-model="nomor_telp_ortu" required>
                </div>
            </div>
            <div class="px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div style="padding: 0px 12px;">
                    <label for="input1" class="form-label">Email<span class="text-danger ms-2">*</span></label>
                    <input type="email" class="form-control rounded-2 px-4" placeholder="Masukkan Email" v-model="email"
                        required>
                </div>
            </div>
            <div class="px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div style="padding: 0px 12px;">
                    <label for="input1" class="form-label">Alamat Lengkap<span class="text-danger ms-2">*</span></label>
                    <textarea class="form-control rounded-2 px-4" placeholder="Masukkan Alamat Lengkap" rows="3"
                        v-model="alamat_lengkap_ortu" required></textarea>
                </div>
            </div>
            <div class="row px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div class="col-md-6 mb-2">
                    <label for="input1" class="form-label">Kecamatan<span class="text-danger ms-2">*</span></label>
                    <input type="text" class="form-control rounded-2 px-4" placeholder="Kecamatan" v-model="kecamatan_ortu"
                        required>
                </div>
                <div class="col-md-6 mb-2">
                    <label for="input1" class="form-label">Kota/ Kabupaten<span class="text-danger ms-2">*</span></label>
                    <input type="text" class="form-control rounded-2 px-4" placeholder="Kota/ Kabupaten"
                        v-model="kota_kabupaten_ortu" required>
                </div>
            </div>
            <div class="row px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div class="col-md-6 mb-2">
                    <label for="input1" class="form-label">Provinsi<span class="text-danger ms-2">*</span></label>
                    <input type="text" class="form-control rounded-2 px-4" placeholder="Provinsi" v-model="provinsi_ortu"
                        required>
                </div>
                <div class="col-md-6 mb-2">
                    <label for="input1" class="form-label">Kode Pos<span class="text-danger ms-2">*</span></label>
                    <input type="number" class="form-control rounded-2 px-4" placeholder="Kode Pos" v-model="kode_pos_ortu"
                        required>
                </div>
            </div>
            <div class="row px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div class="col-md-6 mb-2">
                    <label for="input1" class="form-label">Nomor KTP<span class="text-danger ms-2">*</span></label>
                    <input type="number" class="form-control rounded-2 px-4" placeholder="Nomor KTP" v-model="nomor_ktp"
                        required>
                </div>
                <div class="col-md-6 mb-2">
                    <label for="input1" class="form-label">Nomor KK<span class="text-danger ms-2">*</span></label>
                    <input type="number" class="form-control rounded-2 px-4" placeholder="Nomor KK" v-model="nomor_kk"
                        required>
                </div>
            </div>
            <div class="row px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div class="col-md-6">
                    <label for="input1" class="form-label">Upload Foto KTP<span class="text-danger ms-2">*</span></label>
                    <div class="file-input-container">
                        <div class="drop-zone text-center">
                            <label for="file-input-0" class="hovers-mouse">
                                <img class="rounded img-fluid fit-cover"
                                    :src="require('@/assets/img/illustrations/Add_file.png')" width="28" />
                            </label>
                            <input id="file-input-0" type="file" class="visually-hidden"
                                accept="image/png, image/gif, image/jpeg" @change="selectFile(0, $event)" required>
                            <div class="invalid-feedback">Tolong Upload KTP.</div>
                            <p style="text-align: center; word-wrap: break-word; margin: 0px 0px;" v-if="fileNames[0]">{{
                                fileNames[0] }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <label for="input1" class="form-label">Upload Foto KK<span class="text-danger ms-2">*</span></label>
                    <div class="file-input-container">
                        <div class="drop-zone text-center">
                            <label for="file-input-1" class="hovers-mouse">
                                <img class="rounded img-fluid fit-cover"
                                    :src="require('@/assets/img/illustrations/Add_file.png')" width="28" />
                            </label>
                            <input id="file-input-1" type="file" class="visually-hidden"
                                accept="image/png, image/gif, image/jpeg" @change="selectFile(1, $event)" required>
                            <div class="invalid-feedback">Tolong Upload KK.</div>
                            <p style="text-align: center; word-wrap: break-word; margin: 0px 0px;" v-if="fileNames[1]">{{
                                fileNames[1] }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div class="col-md-6">
                    <label for="input1" class="form-label">Surat Keterangan Tidak Mampu<span
                            class="text-danger ms-2">*</span></label>
                    <div class="file-input-container">
                        <div class="drop-zone text-center">
                            <label for="file-input-2" class="hovers-mouse">
                                <img class="rounded img-fluid fit-cover"
                                    :src="require('@/assets/img/illustrations/Add_file.png')" width="28" />
                            </label>
                            <input id="file-input-2" type="file" class="visually-hidden"
                                accept="application/pdf,application/vnd.ms-excel" @change="selectFile(2, $event)" required>
                            <div class="invalid-feedback">Tolong Upload Surat Keterangan Tidak Mampu.</div>
                            <p style="text-align: center; word-wrap: break-word; margin: 0px 0px;" v-if="fileNames[2]">{{
                                fileNames[2] }}</p>
                        </div>
                    </div>
                    <p class="mt-2 text-muted">*Lampirkan Surat Keterangan Tidak Mampu dari Kepala Desa/ Kelurahan</p>
                </div>
                <div class="col-md-6">
                    <label for="input1" class="form-label">Surat Keterangan Dokter<span
                            class="text-danger ms-2">*</span></label>
                    <div class="file-input-container">
                        <div class="drop-zone text-center">
                            <label for="file-input-3" class="hovers-mouse">
                                <img class="rounded img-fluid fit-cover"
                                    :src="require('@/assets/img/illustrations/Add_file.png')" width="28" />
                            </label>
                            <input id="file-input-3" type="file" class="visually-hidden"
                                accept="application/pdf,application/vnd.ms-excel" @change="selectFile(3, $event)" required>
                            <div class="invalid-feedback">Tolong Upload Surat Keterangan Dokter.</div>
                            <p style="text-align: center; word-wrap: break-word; margin: 0px 0px;" v-if="fileNames[3]">{{
                                fileNames[3] }}</p>
                        </div>
                    </div>
                    <p class="mt-2 text-muted">*Lampirkan Surat Keterangan Dokter kondisi Pasien</p>
                </div>
            </div>
            <div class="row px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div class="col-md-6">
                    <label for="input1" class="form-label">Surat Persetujuan Orang Tua<span
                            class="text-danger ms-2">*</span></label>
                    <div class="file-input-container">
                        <div class="drop-zone text-center">
                            <label for="file-input-4" class="hovers-mouse">
                                <img class="rounded img-fluid fit-cover"
                                    :src="require('@/assets/img/illustrations/Add_file.png')" width="28" />
                            </label>
                            <input id="file-input-4" type="file" class="visually-hidden"
                                accept="application/pdf,application/vnd.ms-excel" @change="selectFile(4, $event)" required>
                            <div class="invalid-feedback">Tolong Upload Surat Persetujuan Orang tua.</div>
                            <p style="text-align: center; word-wrap: break-word; margin: 0px 0px;" v-if="fileNames[4]">{{
                                fileNames[4] }}</p>
                        </div>
                    </div>
                    <p class="mt-2 text-muted">*Lampirkan Surat Persetujuan Orang tua</p>
                </div>
            </div>
            <div class="row mb-4 px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1">
                <div class="col-md-6 text-start">
                    <h6 class=""><span class="text-danger">* </span>Tanda Bintang Wajib Diisi</h6>
                </div>
                <div class="col-md-6 text-end">
                    <button class="btn btn-sm btn-outline-danger px-5 rounded-2 me-2"
                        @click="form1 = true, form2 = false, fileNames = null, scrollToTop()">KEMBALI</button>
                    <button type="submit" class="btn btn-sm btn-danger px-5 rounded-2">LANJUT</button>
                </div>
            </div>
        </form>
    </section>
    <section class="py-5" v-else-if="form3 == true">
        <form role="form" ref="formSubmit3" @submit.prevent="formSubmit3()" novalidate>
            <div class="d-flex align-items-center mb-4 px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1">
                <div class="me-auto p-2">
                    <h3 class="fw-bold">Tuliskan 2 macam keinginan yang diharapkan</h3>
                    <p class="m-0">*(Tidak dalam bentuk uang)</p>
                </div>
                <p class="m-0 p-2">Halaman 3 dari 3</p>
            </div>
            <div class="px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div style="padding: 0px 12px;">
                    <label for="input1" class="form-label">Keinginan/ Harapan 1<span
                            class="text-danger ms-2">*</span></label>
                    <textarea class="form-control rounded-2 px-4" placeholder="Masukkan Keinginan/ Harapan" rows="3"
                        v-model="keinginan_harapan_1" required></textarea>
                </div>
            </div>
            <div class="px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div style="padding: 0px 12px;">
                    <label for="input1" class="form-label">Keinginan/ Harapan 2<span
                            class="text-danger ms-2">*</span></label>
                    <textarea class="form-control rounded-2 px-4" placeholder="Masukkan Keinginan/ Harapan" rows="3"
                        v-model="keinginan_harapan_2" required></textarea>
                </div>
            </div>
            <div class="px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div style="padding: 0px 12px;">
                    <label for="input1" class="form-label">Alasan Harapan diatas<span
                            class="text-danger ms-2">*</span></label>
                    <textarea class="form-control rounded-2 px-4" placeholder="Tuliskan Alasanmu" rows="3" v-model="alasan"
                        required></textarea>
                </div>
            </div>
            <div class="px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1 mb-2">
                <div style="padding: 0px 12px;">
                    <div class="form-check my-5">
                        <input class="form-check-input" type="checkbox" v-model="checkImportant" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            Saya menyatakan bahwa data yang diberikan adalah benar sebagaimana mestinya
                        </label>
                    </div>
                </div>
            </div>
            <div class="row mb-4 px-1 px-lg-5 px-md-5 px-sm-1 mx-1 mx-lg-5 mx-md-5 mx-sm-1">
                <div class="col-md-6 text-start">
                    <h6 class=""><span class="text-danger">* </span>Tanda Bintang Wajib Diisi</h6>
                </div>
                <div class="col-md-6 text-end">
                    <button type="button" class="btn btn-sm btn-outline-danger px-5 rounded-2 me-2"
                        @click="form2 = true, form3 = false, scrollToTop(), fileNames = []">KEMBALI</button>
                    <button type="button" class="btn btn-sm btn-danger px-5 rounded-2" :disabled="!checkImportant"
                        @click="formConfirmation()">KIRIM</button>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="confirmation" tabindex="0" aria-labelledby="confirmation" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <!-- <div class="modal-header">
                            <h6 class="modal-title">Apakah Anda Yakin ?</h6>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div> -->
                        <div class="modal-body text-center">
                            <i class="fa fa-exclamation text-danger text-gradient mb-3" style="font-size: 50px;"></i>
                            <h6 class="modal-title">Apakah Anda Yakin ?</h6>
                            <h4>Data yang diinput sudah benar</h4>
                            <div class="d-flex justify-content-center">
                                <button type="button" class="btn btn-sm btn-outline-primary me-3"
                                    data-bs-dismiss="modal">Kembali</button>
                                <button type="submit" class="btn btn-sm btn-primary" :disabled="isLoading">
                                    <span class="text-white">Benar</span>
                                    <span v-show="isLoading" class="spinner-border spinner-border-sm ms-2" role="status"
                                        aria-hidden="true"></span>
                                </button>
                            </div>
                        </div>
                        <!-- <div class="modal-footer justify-content-center">
                            <button type="button" class="btn btn-sm btn-outline-primary"
                                data-bs-dismiss="modal">Kembali</button>
                            <button type="submit" class="btn btn-sm btn-primary">Benar
                            </button>
                        </div> -->
                    </div>
                </div>
            </div>
        </form>
    </section>

    <!-- Modal -->
    <div class="modal fade" id="myModals" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div class="modal-content">
                <!-- <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div> -->
                <div class="modal-body px-5 py-4">
                    <h1 class="modal-title fs-5 fw-bolder text-center mb-5" id="staticBackdropLabel">KEBIJAKAN DATA PRIBADI
                    </h1>
                    <p>Selamat datang di Website Yayasan Urup Mulia Ananda yang diselenggarakan oleh Yayasan Urup Mulia
                        Ananda
                        selaku penyelenggara disebut juga “YUMA”.
                        <br>
                        <br>
                        Sebelum mengakses dan/atau menggunakan layanan yang tersedia pada Website. Anda wajib membaca,
                        memahami,
                        mematuhi dan setuju untuk tunduk serta terikat pada Kebijakan Data Pribadi, termasuk
                        perubahan-perubahannya.
                        <br>
                        <br>
                        Dengan mengakses Website dan/atau mendaftarkan data diri Anda dan/atau menggunakan layanan-layanan
                        dari YUMA
                        (“Layanan”) melalui Website, anda dengan ini menyadari dan tanpa paksaan dari pihak manapun
                        menyatakan setuju
                        untuk menerima dan terikat dengan Kebijakan Data Pribadi.
                        <br>
                        <br>
                        Jika Anda tidak menyetujui Kebijakan Data Pribadi ini dan termasuk perubahannya, Anda dapat berhenti
                        mengakses
                        dan/atau tidak menggunakan Website termasuk Layanannya.
                        <br>
                        <br>
                        Kebijakan Data Pribadi ini dapat berubah sewaktu-waktu dan akan diberitahukan melalui Website.
                        <br>
                        <br>
                        Anda bertanggung jawab untuk tetap mengetahui pembaharuan Kebijakan Data Pribadi.
                        <br>
                        <br>
                        Keputusan yang diambil berdasarkan Kebijakan Data Pribadi ini termasuk perubahannya bersifat mutlak
                        dan tidak
                        dapat diganggu gugat serta mengikat Anda selama Anda menggunakan Layanan melalui Website.
                        <br>
                        <br>
                        YUMA menghormati dan berkomitmen untuk melindungi Data Pribadi yang Anda berikan kepada YUMA ketika
                        menggunakan Layanan pada Website.
                        <br>
                        <br>
                        Dengan memberikan Data Pribadi ketika menggunakan Website, maka Anda setuju atas pengumpulan dan
                        penggunaan
                        Data Pribadi yang tertuang dalam Kebijakan Data Pribadi ini serta mengungkapkan data tersebut kepada
                        Pihak
                        Ketiga (tenaga medis, rumah sakit, pemasok, mitra, donatur dan pihak lain) yang bekerja sama dengan
                        YUMA atau
                        melakukan tugas atas nama YUMA.
                        <br>
                        <br>
                        Selain Kebijakan Data Pribadi, Anda juga wajib telah membaca, memahami, dan menyetujui seluruh
                        ketentuan yang
                        terdapat dalam Syarat dan Ketentuan juga yang merupakan satu kesatuan yang tidak terpisahkan dari
                        Kebijakan
                        Data Pribadi
                        <br>
                        <br>
                        <strong>1. Pengumpulan Data Pribadi</strong>
                        <br>
                        <br>
                        Pada saat Anda menggunakan Layanan pada Website, YUMA dapat memperoleh atau mengumpulkan Data
                        Pribadi Anda
                        antara lain adalah data dan/atau informasi yang dapat mengidentifikasikan diri Anda termasuk namun
                        tidak
                        terbatas pada nama, alamat, tanggal lahir, nomor ponsel, alamat email, identifikasi (termasuk KTP,
                        SIM, atau
                        Paspor) atau tanda pengenal lainnya yang dikeluarkan oleh pemerintah, foto, kewarganegaraan, nomor
                        telepon
                        catatan medis, foto pasien, foto rontgen, rekam medis dan data lainnya yang dianggap perlu oleh YUMA
                        (“Data
                        Pribadi”). Selain itu, untuk informasi lainnya, seperti profil pribadi, dan/atau nomor pengenal
                        unik, yang
                        dikaitkan atau digabungkan dengan Data Pribadi, maka informasi tersebut juga dianggap sebagai Data
                        Pribadi
                        Anda.
                        <br>
                        <br>
                        YUMA dapat memperoleh atau mengumpulkan Data Pribadi Anda, setiap Anda menggunakan Layanan.
                        <br>
                        <br>
                        Perolehan atau pengumpulan Data Pribadi Anda serta informasi lain dari Anda, dapat dilakukan melalui
                        cara
                        sebagai berikut:
                        <br>
                        <br>
                        · Pembuatan dan/atau pembaruan akun Anda, termasuk di antaranya nama lengkap (username), alamat
                        surel (email),
                        nomor ponsel, kata kunci (password) dan informasi lainnya yang dapat mengidentifikasi Anda; dan/atau
                        <br>
                        <br>
                        · Komunikasi melalui Admin YUMA; dan/atau
                        <br>
                        <br>
                        · Pengisian survei yang dikirimkan oleh YUMA atas data yang dibutuhkan untuk melakukan Layanan;
                        dan/atau
                        <br>
                        <br>
                        · Pengisian data yang dibutuhkan pada saat pembuatan penggalangan dana (Donasi), seperti nama
                        lengkap, alamat
                        surel (email), nomor ponsel, alamat media sosial, dan/atau informasi lainnya sesuai dengan kebutuhan
                        dari
                        Website; dan/atau
                        <br>
                        <br>
                        · Verifikasi dan/atau pencairan Layanan seperti dokumen identitas, dokumen/catatan/rekam medis,
                        Kartu
                        Keluarga, dokumen bukti bayar atau informasi lainnya dalam bentuk dan cara lain yang dapat YUMA
                        minta dari
                        waktu ke waktu;
                        <br>
                        <br>
                        · Pengumpulan dokumen yang dibutuhkan pada saat kerja sama khusus, termasuk namun tidak terbatas
                        seperti
                        kesepakatan tertulis dan dibuat secara terpisah dengan Kebijakan Privasi ini; dan/atau
                        <br>
                        <br>
                        · Data yang terkumpul secara otomatis ketika Anda menggunakan Website seperti, alamat protokol
                        internet
                        (internet protocol address), lokasi geografis (geolocation), sistem operasi (operating system) dan
                        versi
                        peramban (browser version) dari perangkat yang Anda gunakan untuk mengakses Website lalu lintas data
                        (data
                        traffic), rekaman situs, dan data komunikasi lainnya.
                        <br>
                        <br>
                        Anda dengan ini mengetahui dan setuju untuk menyediakan dan mengizinkan YUMA untuk memperoleh dan
                        mengumpulkan
                        Data Pribadi Anda dan/atau data atau informasi terkait lainnya dengan cara yang dimaksud pada
                        ketentuan di
                        atas untuk tujuan-tujuan yang berkaitan dengan Layanan YUMA dan yang ditetapkan dalam Kebijakan Data
                        Pribadi
                        ini.
                        <br>
                        <br>
                        Anda wajib selalu memastikan bahwa Data Pribadi yang Anda serahkan kepada YUMA adalah Informasi
                        Pribadi yang
                        benar, lengkap, akurat, dan tidak menyesatkan. Anda harus memberitahukan kepada YUMA apabila
                        terdapat
                        perubahan pada Data Pribadi Anda sebelum perubahan tersebut berlaku efektif.
                        <br>
                        <br>
                        Setiap ketidaklengkapan, ketidakabsahan, dan/atau ketidakakuratan Data Pribadi yang dikirimkan
                        kepada YUMA
                        dapat menjadi kegagalan dalam mematuhi persyaratan hukum atau ketidakmampuan YUMA untuk menyediakan
                        Layanan
                        kepada Anda. Selanjutnya, Anda dengan ini membebaskan YUMA dari seluruh konsekuensi yang timbul dari
                        kelalaian
                        atau kesalahan Anda dalam menjaga kebenaran, kelengkapan, dan akurasi Data Pribadi Anda, dan
                        kegagalan Anda
                        untuk menjaga validitas, kelengkapan, keakuratan Data Pribadi yang dikirimkan kepada YUMA.
                        <br>
                        <br>
                        <strong>2. Pembatasan Usia</strong>
                        <br>
                        <br>
                        Anda yang memberikan data melalui Website menyatakan bahwa Anda telah berusia 17 tahun atau lebih.
                        <br>
                        <br>
                        <strong>3. Penggunaan Data Pribadi</strong>
                        <br>
                        <br>
                        YUMA dapat mengolah, menganalisis, dan/atau menggunakan Data Pribadi yang YUMA dapatkan tentang Anda
                        untuk
                        tujuan sebagai berikut maupun tujuan lain yang diizinkan oleh peraturan perundang-undangan yang
                        berlaku, di
                        antaranya:
                        <br>
                        <br>
                        a. Memungkinkan YUMA untuk menyediakan layanan yang Anda minta pada Website
                        <br>
                        <br>
                        b. Membantu pemerintah yang berwenang dan/atau institusi lainnya yang ditunjuk oleh pemerintah yang
                        berwenang
                        atau memiliki kerja sama dengan YUMA, untuk tujuan pelacakan kontak, mendukung inisiatif, kebijakan
                        atau
                        program pemerintah, kesehatan publik dan tujuan lainnya sebagaimana dibutuhkan secara wajar.
                        <br>
                        <br>
                        c. Tujuan proses hukum dalam bentuk apapun antara Anda dengan YUMA, atau antara Anda dengan pihak
                        lain,
                        sehubungan dengan, atau terkait dengan layanan, untuk keperluan proses hukum tersebut.
                        <br>
                        <br>
                        <strong>4. Pengungkapan kepada Pihak Ketiga</strong>
                        <br>
                        <br>
                        YUMA terkadang diharuskan untuk menampilkan, mengumumkan, mengirimkan, menyebarluaskan dan/atau
                        memberikan
                        akses Data Pribadi kepada Pihak Ketiga. Anda dengan ini menyatakan telah memberikan persetujuan,
                        izin, dan
                        wewenang kepada YUMA untuk menampilkan, mengumumkan, mengirimkan, dan/atau menyebarluaskan serta
                        memberikan
                        akses atas Data Pribadi kepada pihak ketiga yaitu:
                        <br>
                        <br>
                        a. Pihak ketiga dalam rangka menyediakan Layanan untuk Anda dan sehubungan dengan tujuan di mana
                        Pihak ketiga
                        tersebut secara teknis diperlukan untuk memproses Layanan atau tujuan kerja sama YUMA dengan Pihak
                        Ketiga
                        tersebut yang dapat mencakup diperbolehkannya Pihak Ketiga tersebut untuk memperkenalkan atau
                        menawarkan
                        produk atau jasa kepada Anda, melakukan autentikasi terhadap Anda atau menghubungkan Anda dengan
                        akun atau
                        melakukan kegiatan lain termasuk penelitian, analisis dan pengembangan dari Layanan.
                        <br>
                        <br>
                        b. Penasihat profesional, lembaga penegak hukum, perusahaan asuransi, pemerintah dan pihak berwenang
                        atau
                        instansi lainnya di mana YUMA berkewajiban untuk melakukan pengungkapan sesuai kebutuhan, permintaan
                        yang sah,
                        dan hukum yang berlaku.
                        <br>
                        <br>
                        <strong>5. Penyimpanan Informasi Pribadi</strong>
                        <br>
                        <br>
                        Keamanan Data Pribadi Anda adalah hal yang sangat penting bagi YUMA dan Data Pribadi Anda hanya akan
                        disimpan
                        selama diperlukan untuk memenuhi tujuan dari pengumpulannya atau selama penyimpanan tersebut
                        diperlukan atau
                        diperbolehkan oleh peraturan perundang-undangan yang berlaku. YUMA memastikan bahwa Data Pribadi
                        yang Anda
                        berikan kepada YUMA dikumpulkan dan disimpan dengan aman. Namun, harap diketahui bahwa tidak ada
                        metode
                        terkait transmisi data melalui internet, atau metode penyimpanan elektronik yang benar-benar 100%
                        (seratus
                        persen) aman.
                        <br>
                        <br>
                        Anda mengakui bahwa YUMA tidak dapat menjamin keutuhan dan keakuratan Data Pribadi apa pun yang Anda
                        kirimkan
                        melalui internet, atau menjamin bahwa Data Pribadi tersebut tidak akan dicegat, diakses,
                        diungkapkan, diubah
                        atau dihancurkan oleh pihak ketiga yang tidak berwenang, karena faktor-faktor di luar kendali YUMA.
                        <br>
                        <br>
                        Dalam hal YUMA memberikan Informasi Pribadi Anda kepada institusi pemerintah yang berwenang dan/atau
                        institusi
                        lainnya yang ditunjuk oleh pemerintah yang berwenang atau memiliki kerja sama dengan YUMA, Anda
                        menyetujui dan
                        mengakui bahwa penyimpanan Data Pribadi Anda oleh institusi terkait akan mengikuti kebijakan
                        penyimpanan data
                        masing-masing institusi tersebut dan YUMA tidak akan bertanggung jawab atas penyimpanan Data Pribadi
                        Anda.
                        <br>
                        <br>
                        <strong>6. Tautan ke Situs Eksternal</strong>
                        <br>
                        <br>
                        Website dapat juga memuat tautan ke situs eksternal. Tautan situs eksternal yang ada di Website
                        tersebut
                        adalah sepenuhnya bukan milik YUMA melainkan milik Pihak Ketiga yang memiliki hubungan kerja sama
                        dengan YUMA.
                        <br>
                        <br>
                        Oleh karena itu, YUMA tidak bertanggung jawab atas perolehan, pengumpulan, pengolahan,
                        penganalisisan,
                        penggunaan, penyimpanan, penampilan, pengumuman, pengiriman, penyebarluasan, penghapusan dan
                        pemusnahan Data
                        Pribadi Anda oleh pihak eksternal tersebut. Jika Anda memberikan informasi langsung ke situs
                        tersebut,
                        kebijakan data pribadi serta syarat dan ketentuan situs tersebut akan berlaku, YUMA tidak
                        bertanggung jawab
                        atas kegiatan pengolahan informasi atau kebijakan data pribadi dari situs tersebut.
                        <br>
                        <br>
                        <strong>7. Cookie(s)</strong>
                        Cookie adalah berkas data kecil yang ditempatkan browser pada perangkat internet Anda. Dengan
                        cookie, fitur
                        aplikasi/situs web yang Anda akses dapat menyimpan informasi atau mengingat tindakan dan preferensi
                        Anda dari
                        waktu ke waktu. Sebagian besar browser internet mendukung cookie. Namun, Anda dapat mengatur browser
                        yang Anda
                        gunakan untuk menolak beberapa jenis cookie tertentu atau cookie spesifik. Di samping itu, Anda
                        dapat
                        menghapus cookie kapan saja. YUMA menggunakan cookie untuk berbagai tujuan, termasuk untuk mengingat
                        preferensi penelusuran Anda, membantu Anda untuk menggunakan Layanan dan melindungi data Anda.
                        <br>
                        <br>
                        <strong>8. Data Anonim</strong>
                        Anda dengan ini sepakat bahwa YUMA berhak menghapus data-data yang mengidentifikasikan Anda dari
                        Data Pribadi
                        Anda atau mengkombinasikan Data Pribadi Anda dengan data-data lainnya, sehingga data-data yang
                        mengandung
                        identifikasi Anda atau dapat dikaitkan dengan Anda dalam Data Pribadi Anda terhapus atau tergabung
                        dengan data
                        lain yang menyebabkan data-data tersebut tidak lagi dapat diidentifikasikan terhadap Anda dan
                        menjadi data
                        anonim.
                        <br>
                        <br>
                        Anda dengan ini sepakat bahwa YUMA berhak untuk selalu menyimpan, mengirimkan, mengungkapkan,
                        memproses,
                        memanfaatkan, dan menghapus/menghancurkan data anonim, mengingat data anonim bukan lagi merupakan
                        Data Pribadi
                        Anda dan tidak lagi dapat diidentifikasikan terhadap diri Anda.
                        <br>
                        <br>
                        <strong>9. Keberlakuan</strong>
                        <br>
                        <br>
                        Jika terdapat suatu ketentuan dalam Kebijakan Data Pribadi yang ternyata diketahui melanggar
                        ketentuan
                        perundang-undangan yang berlaku, baik sebagian maupun seluruhnya, maka ketentuan yang dianggap
                        melanggar
                        tersebut dikesampingkan dari Kebijakan Data Pribadi dan atas ketentuan lain dalam Kebijakan Data
                        Pribadi tetap
                        berlaku dan mengikat.
                        <br>
                        <br>
                        <strong>10. Ketentuan Lain</strong>
                        <br>
                        <br>
                        a. Kebijakan Data Pribadi ini tunduk, diatur, dan dilaksanakan sesuai dengan hukum Republik
                        Indonesia.
                        <br>
                        <br>
                        b. Jika terjadi perselisihan dalam interpretasi dan implementasi Kebijakan Data Pribadi ini, Anda
                        setuju untuk
                        menyelesaikannya dengan musyawarah. Jika dalam jangka waktu 30 (tiga puluh) hari kalender Anda dan
                        YUMA tidak
                        dapat mencapai suatu kesepakatan melalui musyawarah tersebut, maka perselisihan tersebut akan
                        diselesaikan
                        melalui Pengadilan Negeri Tangerang.
                        <br>
                        <br>
                        <strong>11. Hubungi Yayasan</strong>
                        <br>
                        <br>
                        Jika Anda memiliki pertanyaan, permintaan, keluhan, atau kepentingan penyampaian informasi apapun
                        terkait
                        Kebijakan Data Pribadi , Anda dapat menghubungi admin YUMA di admin@yuma.or.id
                        <br>
                        <br>
                    </p>
                    <div class="text-end">
                        <button class="btn btn-primary" data-bs-target="#myModal2"
                            data-bs-toggle="modal">Selanjutnya</button>
                    </div>
                </div>
                <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Understood</button>
        </div> -->
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="myModal2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div class="modal-content">
                <!-- <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div> -->
                <div class="modal-body px-5 py-4">
                    <!-- <h1 class="modal-title fs-5 fw-bold text-center mb-5" id="staticBackdropLabel">KEBIJAKAN DATA PRIBADI</h1> -->
                    <p>Yayasan Urup Mulia Ananda (YUMA) merupakan suatu badan hukum yang bergerak dibidang kegiatan sosial
                        dan
                        merupakan pihak yang mengelola website yuma.or.id (“Website”). YUMA dapat memodifikasi, menambah,
                        dan/atau
                        mengubah syarat dan ketentuan atau kebijakan penggunaan Website (“Syarat dan Ketentuan”) setiap saat
                        dengan/tanpa memberikan pemberitahuan kepada Anda melalui media komunikasi milik YUMA. YUMA
                        mengimbau kepada
                        Anda untuk secara berkala meninjau Syarat dan Ketentuan ini agar tetap mendapat informasi tentang
                        bagaimana
                        ketentuan yang berlaku di Website.
                        <br>
                        <br>
                        <strong>A. Definisi dan Interpretasi secara Umum</strong>
                        <br>
                        <br>
                        1. Website adalah website yang dikelola oleh YUMA dengan tujuan sebagai media informasi dan
                        publikasi dengan
                        domain yuma.or.id
                        <br>
                        <br>
                        2. Syarat dan Ketentuan adalah persetujuan mengikat antara Anda dan YUMA berisikan seperangkat
                        ketentuan yang
                        mengatur hak, kewajiban, dan/atau tanggung jawab.
                        <br>
                        <br>
                        3. Layanan adalah layanan penyaluran Donasi.
                        <br>
                        <br>
                        4. Anda adalah pihak yang menggunakan layanan pada Website, termasuk namun tidak terbatas Donatur,
                        Penerima
                        Manfaat, dan/atau pihak lain yang sekedar berkunjung ke Website.
                        <br>
                        <br>
                        5. Penerima Manfaat adalah Anda sebagai pihak yang berhak untuk mendapatkan penyaluran dari hasil
                        Donasi
                        sesuai dengan Syarat dan Ketentuan ini.
                        <br>
                        <br>
                        6. Donatur adalah Anda yang memberikan sejumlah sumbangan sebagai bantuan serta dukungan terhadap
                        Penerima
                        Manfaat.
                        <br>
                        <br>
                        7. Donasi adalah aktivitas pemberian sumbangan secara sukarela yang diberikan oleh Donatur yang
                        dinyatakan
                        dalam mata uang Rupiah atau dalam bentuk barang/jasa.
                        <br>
                        <br>
                        8. Akun adalah suatu pengaturan antara YUMA dengan Anda, sehingga Anda dapat mengakses fitur yang
                        terdapat di
                        dalam Website setelah melakukan pendaftaran Data Pribadi, nama (username) dan kata sandi (password).
                        <br>
                        <br>
                        9. Verifikasi adalah upaya pemeriksaan terhadap suatu kebenaran informasi yang disampaikan oleh Anda
                        berkaitan
                        dengan Data Pribadi, dokumen medis dan informasi lainnya yang sesuai dengan pengguna Layanan pada
                        Website.
                        <br>
                        <br>
                        10. Data Pribadi adalah informasi dan/atau dokumen yang dapat diidentifikasi atau terasosiasi
                        terhadap Anda
                        sebagaimana YUMA tentukan dari waktu ke waktu, seperti nama lengkap, nomor ponsel, alamat surel
                        (email), nama
                        Penerima Manfaat, foto, video, gambar, dokumen identitas, dokumen medis, surat keterangan, dokumen
                        Kartu
                        Keluarga (“KK”), dan/atau informasi lainnya.
                        <br>
                        <br>
                        <strong>B. Kedudukan YUMA</strong>
                        <br>
                        <br>
                        1. YUMA dengan ini menegaskan bahwa Website ini dibuat sebagai untuk tujuan sosial untuk memudahkan
                        Anda
                        menerima Layanan.
                        <br>
                        <br>
                        2. YUMA berhak untuk mengganti, mengubah, menangguhkan, dan/atau menghentikan semua atau sebagian
                        Syarat dan
                        Ketentuan yang disediakan dari Website. setiap saat baik tanpa dan/atau dengan pemberitahuan
                        sebelumnya.
                        <br>
                        <br>
                        Dengan tetap menggunakan Layanan, maka Anda dianggap menyetujui perubahan-perubahan yang telah
                        dilakukan.
                        <br>
                        <br>
                        3. Seluruh kegiatan yang ada pada Website ini termasuk Layanan tunduk pada peraturan
                        perundang-undangan yang
                        berlaku di Indonesia.
                        <br>
                        <br>
                        <strong>C. Kewenangan YUMA</strong>
                        <br>
                        <br>
                        1. YUMA berdasarkan pertimbangannya sendiri berhak secara mutlak untuk :
                        <br>
                        <br>
                        a. Menyetujui atau menolak Anda sebagai Penerima Manfaat; dan/atau
                        <br>
                        <br>
                        b. menentukan jenis, bentuk dan/atau jumlah Donasi yang akan Anda terima sebagai Penerima Manfaat.
                        <br>
                        <br>
                        2. Anda sebagai Penerima Manfaat memahami dan menyetujui bahwa YUMA dapat mengalihkan dan/atau
                        membatalkan
                        Donasi yang akan diberikan kepada Anda sebagai Penerima Manfaat, apabila :
                        <br>
                        <br>
                        a. Anda sebagai Penerima Manfaat terbukti melakukan pelanggaran Kebijakan Data Pribadi dan/atau
                        Syarat dan
                        Ketentuan; dan/atau
                        <br>
                        <br>
                        b. Penerima Manfaat telah meninggal dunia; dan/atau
                        <br>
                        <br>
                        c. Kondisi tertentu yang menurut pertimbangan YUMA dapat dilakukan pengalihan dan/atau pembatalan
                        Donasi.
                        <br>
                        <br>
                        3. Sehubungan dengan ayat 1 dan 2 butir c ini, Anda dengan ini menyatakan dan setuju bahwa Anda
                        tidak berhak
                        menuntut dan/atau mengajukan gugatan dan/atau tuntutan dan/atau permintaan ganti rugi dalam bentuk
                        apapun
                        kepada YUMA sehubungan dengan hal tersebut diatas.
                        <br>
                        <br>
                        <strong>D. Hak Kekayaan Intelektual</strong>
                        <br>
                        <br>
                        1. Anda wajib memahami dan menyetujui bahwa seluruh isi, materi, dan komponen lainnya (termasuk
                        namun tidak
                        terbatas pada logo, grafis, ikon, dan header) yang tersedia pada Website ini adalah merupakan hak
                        kekayaan
                        intelektual milik YUMA yang dilindungi oleh hukum.
                        <br>
                        <br>
                        2. Tanpa adanya izin tertulis dari YUMA, Anda tidak berhak untuk menggunakan, menjual, melisensikan,
                        menyewakan, memodifikasi, mendistribusikan, menyalin, memperbanyak, mengirimkan, mempublikasikan
                        tampilan,
                        memperlihatkan kepada publik, menerbitkan, mengadaptasi, mengedit, atau membuat karya turunan dari
                        isi atau
                        materi yang terdapat pada Website, untuk yang bersifat komersial.
                        <br>
                        <br>
                        <strong>E. Penggantian Kerugian</strong>
                        <br>
                        <br>
                        Anda setuju bahwa Anda diwajibkan mengganti rugi kepada YUMA jika terbukti melakukan tindakan yang
                        melanggar
                        hukum, serta akan melepaskan YUMA atas setiap klaim, biaya, kerusakan, kerugian, kewajiban dan beban
                        yang
                        timbul dari hal-hal yang di luar kendali YUMA termasuk namun tidak terbatas pada pelanggaran yang
                        dilakukan
                        oleh Anda terhadap Syarat dan Ketentuan ini, penggunaan layanan Website yang tidak semestinya
                        dan/atau
                        pelanggaran yang Anda lakukan terhadap hukum yang berlaku atau pelanggaran yang merugikan pihak
                        ketiga
                        lainnya.
                        <br>
                        <br>
                        <strong>F. Hukum yang Mengatur dan Yurisdiksi</strong>
                        <br>
                        <br>
                        Syarat dan Ketentuan ini diatur berdasarkan hukum Negara Kesatuan Republik Indonesia. Setiap
                        perselisihan,
                        tindakan, tuntutan atau aksi yang timbul atau sehubungan dengan Syarat dan Ketentuan atau layanan
                        harus
                        diselesaikan pada Pengadilan Negeri Tangerang.
                    </p>
                    <div class="form-check my-5">
                        <input class="form-check-input" type="checkbox" v-model="agreeTerms" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            Saya menyatakan bahwa saya setuju dengan term & condition yang berlaku.
                        </label>
                    </div>
                    <div class="text-end">
                        <button class="btn btn-primary" data-bs-dismiss="modal" :disabled="!agreeTerms">Setuju</button>
                    </div>
                </div>
                <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Understood</button>
        </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import FormData from 'form-data'
import axios from '@/store/axios';
import bootstrap from '@/vendor/js/bootstrap.js'; // Import Bootstrap JavaScript


export default {
    name: 'ProfilView',
    components: {
    },
    data() {
        return {
            //form
            form1: true,
            form2: false,
            form3: false,
            fileNames: [],
            formData: new FormData(),
            selectedFiles: [[], [], [], [], []], // Array to store selected files for each file input

            //form1 
            nama_lengkap: '',
            jenis_kelamin: '',
            tempat_lahir: '',
            tanggal_lahir: '',
            alamat_lengkap: '',
            kecamatan: '',
            kota_kabupaten: '',
            provinsi: '',
            kode_post: '',
            sekolah: '',
            kelas: '',
            jenis_kanker: '',
            selectedStadium: '',

            //form2
            nama_lengkap_ortu: '',
            alamat_lengkap_ortu: '',
            email: '',
            // hubungan_anak: '',
            nomor_telp_ortu: '',
            provinsi_ortu: '',
            kota_kabupaten_ortu: '',
            kecamatan_ortu: '',
            kode_pos_ortu: '',
            nomor_ktp: '',
            nomor_kk: '',

            //form3
            keinginan_harapan_1: '',
            keinginan_harapan_2: '',
            alasan: '',

            checkImportant: false,
            isLoading: false,
            agreeTerms: false,
        };
    },
    mounted() {
        const myModal = bootstrap.Modal.getOrCreateInstance("#myModals")
        myModal.show();
    },
    methods: {
        handleImageChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.selectedImage = file;
            }
        },
        uploadImage() {
            const file = this.selectedImage;
            if (!file) {
                console.log('No file selected');
                return;
            }

            try {
                const formData = new FormData();
                formData.append('nama_lengkap', 'ghifar maulana');
                formData.append('email_nomor_telp', '08123567890');
                formData.append('bukti_pembayaran', file);

                const response = axios.post('api/bukti_pembayaran_', formData);

                // Handle the response after successful upload
                console.log(response);
            } catch (error) {
                // Handle the error if the upload fails
                console.error(error);
            }


        },
        dragOver(event) {
            event.preventDefault();
            event.currentTarget.classList.add('highlight');
        },
        dragLeave(event) {
            event.preventDefault();
            event.currentTarget.classList.remove('highlight');
        },
        dropFile(index, event) {
            event.preventDefault();
            event.currentTarget.classList.remove('highlight');
            const files = event.dataTransfer.files;
            this.getFileNames(files, index);
        },
        selectFile(index, event) {
            const files = event.target.files;
            this.getFileNames(files, index);

            // Get the selected files from the input element
            this.selectedFiles[index] = event.target.files;

            // Log the formData after appending data
            for (const pair of this.formData.entries()) {
                console.log(pair[0], pair[1]);
            }
        },
        getFileNames(files, index) {
            this.fileNames[index] = [];
            for (let i = 0; i < files.length; i++) {
                this.fileNames[index].push(files[i].name);
            }
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        formSubmit1() {
            if (this.$refs.formSubmit1.checkValidity()) {
                //change view
                this.form1 = false
                this.form2 = true

                //scroll up
                this.scrollToTop()
            }
            else {
                this.$refs.formSubmit1.classList.add('was-validated')
            }
        },
        formSubmit2() {
            if (this.$refs.formSubmit2.checkValidity()) {

                //change view
                this.form2 = false
                this.form3 = true

                //scroll up
                this.scrollToTop()
            }
            else {
                this.$refs.formSubmit2.classList.add('was-validated')
            }
        },
        formSubmit3() {
            this.isLoading = true;
            const myModal = bootstrap.Modal.getOrCreateInstance("#confirmation")

            //input form 1
            this.formData.append('nama_lengkap', this.nama_lengkap);
            this.formData.append('jenis_kelamin', this.jenis_kelamin);
            this.formData.append('tempat_lahir', this.tempat_lahir);
            this.formData.append('tanggal_lahir', this.tanggal_lahir);
            this.formData.append('alamat_lengkap', this.alamat_lengkap);
            this.formData.append('kecamatan', this.kecamatan);
            this.formData.append('kota_kabupaten', this.kota_kabupaten);
            this.formData.append('provinsi', this.provinsi);
            this.formData.append('kode_post', this.kode_post);
            this.formData.append('sekolah', this.sekolah);
            this.formData.append('kelas', this.kelas);
            this.formData.append('jenis_kanker', this.jenis_kanker);
            this.formData.append('stadium', this.selectedStadium);

            //input form 2
            this.formData.append('nama_lengkap_ortu', this.nama_lengkap_ortu);
            this.formData.append('alamat_lengkap_ortu', this.alamat_lengkap_ortu);
            this.formData.append('email', this.email);
            this.formData.append('nomor_telp_ortu', this.nomor_telp_ortu);
            this.formData.append('provinsi_ortu', this.provinsi_ortu);
            this.formData.append('kota_kabupaten_ortu', this.kota_kabupaten_ortu);
            this.formData.append('kecamatan_ortu', this.kecamatan_ortu);
            this.formData.append('kode_pos_ortu', this.kode_pos_ortu);
            this.formData.append('nomor_ktp', this.nomor_ktp);
            this.formData.append('nomor_kk', this.nomor_kk);

            //input form 3
            this.formData.append('keinginan_harapan_1', this.keinginan_harapan_1);
            this.formData.append('keinginan_harapan_2', this.keinginan_harapan_2);
            this.formData.append('alasan', this.alasan);

            const files = this.selectedFiles;
            for (let i = 0; i < files.length; i++) {
                const indexes = i == 0 ? 'upload_ktp' : i == 1 ? 'upload_kk' : i == 2 ? 'upload_surketima' : i == 3 ? 'upload_surketdok' : i == 4 ? 'upload_persetujuan_ortu' : '';
                this.formData.append(indexes, new Blob(files[i]));
            }

            axios.post('api/registration_', this.formData)
                .then(() => {
                    this.isLoading = false
                    //scroll up
                    this.$SwalCenter('success', 'Data Berhasil Disimpan', 'Silahkan Menunggu Beberapa Saat untuk proses validasi data')
                    this.$router.push('/pendaftaran')
                    myModal.hide()

                })
                .catch(() => {
                    this.isLoading = false
                    this.$SwalCenter('error', 'Data Gagal Tersimpan', 'Silahkan Menunggu Beberapa Saat untuk mengulang kembali')
                });
        },

        formConfirmation() {
            if (this.$refs.formSubmit3.checkValidity()) {
                const myModal = bootstrap.Modal.getOrCreateInstance("#confirmation")
                myModal.show();
            } else {
                this.$refs.formSubmit3.classList.add('was-validated')
            }
        },
        send() {
            // this.$SwalCenter('success', 'Data anda berhasil dikirim', 'Mohon ditunggu untuk proses validasi')
            // window.scrollTo({
            //     top: 0,
            //     behavior: 'smooth'
            // });
            // this.$router.push('/pendaftaran')
            // console.log(file)
        }
    }
}
</script>

<style scoped>
.file-input-container {
    position: relative;
}

.drop-zone {
    border: 2px solid #ced4da;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

.file-names {
    margin-top: 10px;
}

.file-names ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.file-names li {
    margin-bottom: 5px;
}

.hovers-mouse {
    cursor: pointer;
    /* Set the cursor to look like it wants to click */
}
</style>
  