<template>
  <header>
    <div class="py-4 py-xl-5 fit-img text-center" style="background-size: cover; padding: 80px 0px 80px !important; background-position: 65% 80%;"
      :style="{ 'background-image': 'url(' + require('@/assets/img/illustrations/splashimg1.png') + ')' }">
      <h1 class="fw-bold mb-4 text-white">Wujudkan <span class="text-primary">Mimpi!</span></h1>
      <p class="text-white text-center px-lg-5 px-md-5 px-sm-3 px-3 mb-4">Imajinasi anak-anak tidak terbatas pada mimpi belaka.
        Impian dan harapan mereka memiliki makna yang berharga untuk bisa berjuang demi kesembuhan. Di tengah kondisi dan
        keterbatasan yang mereka alami, YUMA memberi mereka semangat lewat keinginan dari impian dan harapannya untuk
        diwujudkan melalui program Harapanku. YUMA ingin mewujudkan impian dan harapan mereka, serta memberi energi
        positif dan senyum bahagia untuk menjalani perawatannya.</p>
    </div>
  </header>
  <section class="py-5">
    <div class="px-5 py-4 py-xl-5">
      <div class="row gy-4 gy-md-0 align-items-center">
        <div
          class="col-md-7 text-start text-md-start d-flex d-sm-flex d-md-flex justify-content-center align-items-center justify-content-md-start align-items-md-center justify-content-xl-center">
          <div class="justify w-lg-75 w-md-75 w-sm-100">
            <h1 class="fw-bolder mb-2" style="color: #371065; font-size: 36px;">Harapanku</h1>
            <img class="rounded img-fluid fit-cover mb-3" :src="require('@/assets/img/illustrations/slash1.png')"
              width="150" />
            <p class="fw-lighter mb-4 pe-lg-5 pe-md-5 pe-sm-0">
              Setiap anak memiliki impian dan harapan yang ingin mereka wujudkan, tak terkecuali para anak penderita
              kanker. Mereka berjuang untuk kesembuhannya, melewati sakit yang diderita untuk bisa hidup normal dan meraih
              cita-citanya.
            </p>
          </div>
        </div>
        <div class="col-md-5">
          <div class="text-center">
            <img class="rounded img-fluid fit-cover" :src="require('@/assets/img/illustrations/img1.png')" width="400" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="py-5" style="background-color: #ECE0FA;">
    <div class="px-5 py-4 py-xl-5">
      <div class="row gy-4 gy-md-0 align-items-center">
        <div class="col-md-5 order-1 order-sm-1 order-md-0 order-xl-0">
          <div class="text-center">
            <img class="rounded img-fluid fit-cover" :src="require('@/assets/img/illustrations/img2.png')" width="400" />
          </div>
        </div>
        <div
          class="col-md-7 text-start text-md-start d-flex d-sm-flex d-md-flex justify-content-center align-items-center justify-content-md-start align-items-md-center justify-content-xl-center order-0 order-sm-0 order-md-1 order-xl-1">
          <div class="justify w-lg-75 w-md-75 w-sm-100">
            <img class="rounded img-fluid fit-cover mb-3" :src="require('@/assets/img/illustrations/slash1.png')"
              width="150" />
            <p class="fw-lighter mb-4 pe-lg-5 pe-md-5 pe-sm-0">
              Keterbatasan akibat sakit yang mereka derita tidak boleh mengubur impian dan harapan untuk masa depan. YUMA
              hadir untuk mewujudkan keinginan mereka melalui program Harapanku.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="py-5">
    <div class="px-5 py-4 py-xl-5">
      <div class="row gy-4 gy-md-0 align-items-center">
        <div
          class="col-md-7 text-start text-md-start d-flex d-sm-flex d-md-flex justify-content-center align-items-center justify-content-md-start align-items-md-center justify-content-xl-center">
          <div class="justify w-lg-75 w-md-75 w-sm-100">
            <img class="rounded img-fluid fit-cover mb-3" :src="require('@/assets/img/illustrations/slash1.png')"
              width="150" />
            <p class="fw-lighter mb-4 pe-lg-5 pe-md-5 pe-sm-0">
              Membeli mainan baru, pakaian baru, pergi berlibur, bermain di taman wisata atau pantai, membeli peralatan
              sekolah, alat bantu kesehatan, bertemu idola favorit atau kamu mempunyai keinginan lain? Impian dan harapan
              kamu yang terpendam mungkin bisa YUMA bantu wujudkan! Kamu bisa tulis impian dan harapan kamu di kolom
              keinginan ketika mendaftar di YUMA!
            </p>
          </div>
        </div>
        <div class="col-md-5">
          <div class="text-center">
            <img class="rounded img-fluid fit-cover" :src="require('@/assets/img/illustrations/img3.png')" width="400" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="py-5" style="background-color: #ECE0FA;">
    <div class="px-5 py-4 py-xl-5">
      <div class="row gy-4 gy-md-0 align-items-center">
        <div class="col-md-5 order-1 order-sm-1 order-md-0 order-xl-0">
          <div class="text-center">
            <img class="rounded img-fluid fit-cover" :src="require('@/assets/img/illustrations/img4.png')" width="400" />
          </div>
        </div>
        <div
          class="col-md-7 text-start text-md-start d-flex d-sm-flex d-md-flex justify-content-center align-items-center justify-content-md-start align-items-md-center justify-content-xl-center order-0 order-sm-0 order-md-1 order-xl-1">
          <div class="justify w-lg-75 w-md-75 w-sm-100">
            <img class="rounded img-fluid fit-cover mb-3" :src="require('@/assets/img/illustrations/slash1.png')"
              width="150" />
            <p class="fw-lighter mb-4 pe-lg-5 pe-md-5 pe-sm-0">
              Senyum, tawa, kebahagiaan, dan keceriaan mereka menjadi semangat YUMA untuk terus mengabdi dan mewujudkan
              banyak impian serta harapan anak-anak penderita kanker.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <section class="py-5">
    <div class="px-5 py-4 py-xl-5">
      <div class="row gy-4 gy-md-0 align-items-center">
        <div
          class="col-md-7 text-start text-md-start d-flex d-sm-flex d-md-flex justify-content-center align-items-center justify-content-md-start align-items-md-center justify-content-xl-center">
          <div class="justify w-lg-75 w-md-75 w-sm-100">
            <img class="rounded img-fluid fit-cover mb-3" :src="require('@/assets/img/illustrations/slash1.png')"
              width="150" />
            <p class="fw-lighter mb-4 pe-lg-5 pe-md-5 pe-sm-0">
              Bukan hanya YUMA, kamu juga bisa ambil bagian menebar kebahagiaan dan ikut mewujudkan keinginan anak-anak
              penderita kanker melalui Donasi. YUMA akan menyalurkan donasi kamu untuk sama-sama kita wujudkan impian dan
              harapan mereka yang tertunda!
            </p>
          </div>
        </div>
        <div class="col-md-5">
          <div class="text-center">
            <img class="rounded img-fluid fit-cover" :src="require('@/assets/img/illustrations/img5.png')" width="400" />
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- <section style="background-color: #1F0939;">
    <div class="row">
      <div
        class="col-md-5 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-center align-items-end">
        <div class="p-2 p-sm-2 p-md-0 p-lg-0">
          <img class="img-fluid fit-cover" :src="require('@/assets/img/illustrations/img6.png')" width="400" />
        </div>
      </div>
      <div class="col-md-7 p-5 text-center">
        <h6 class="fw-semibold text-white mb-4">Donasi YUMA</h6>
        <p class="text-white mb-4" style="font-size: 16px;">Anda bisa langsung berdonasi dengan melakukan
          transfer ke rekening dibawah
          ini, setelah itu<br>anda diwajibkan untuk upload bukti pembayaran dengan mengklik “Konfirmasi Pembayaran”</p>
        <p class="text-white fw-bold mb-4" style="font-size: 16px;">Mandiri/ 155 00 8181919 8<br>A.n Yayasan Urup
          Mulia Ananda</p>
        <a type="button" class="btn btn-primary px-5 py-2 my-3 rounded-2" href="/formulirPembayaran">Konfirmasi
          Pembayaran</a>
      </div>
    </div>
  </section> -->
  <section class="mt-5 py-5">
  </section>
</template>
  
<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HarapanView',
  components: {
  }
}
</script>
  