<template>
  <div id="app">
    <!-- Navbar component -->
    <navbar-top></navbar-top>

    <!-- Main content -->
    <div style="min-height : 50vh !important">
      <router-view />
    </div>

    <!-- Footer component -->
    <footer-bot></footer-bot>
  </div>
</template>

<script>
import NavbarTop from "@/components/NavbarTop.vue";
import FooterBot from "@/components/FooterBot.vue";

export default {
  name: "App",
  components: {
    NavbarTop,
    FooterBot
  },

}
</script>

<style>
#app {
  font-family: 'roboto', sans-serif;
  /* display: flex;
  flex-direction: column;
  min-height: 100vh; */
}

/* .container {
  flex: 1;
} */

@font-face {
  font-family: 'roboto';
  src: url('@/assets/fonts/Poppins-Regular.ttf') format('truetype');
  /* Add additional src lines for other font file formats if available */
}
</style>
