import Swal from 'sweetalert2';
export function SwalCenter(icon, title, text) {
    // Sweet Alert
    Swal.fire({
        position: 'center',
        icon: icon,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 4000
    })
}