
<template>
    <header>
        <div class="px-0 px-lg-5 px-md-5 px-sm-0 py-4 py-xl-5 fit-img text-center">
            <h1 class="fw-bold my-4">Apa Yang Bisa Kami Bantu?</h1>
            <div class="d-flex justify-content-center">
                <p class="w-75 text-center text-muted px-lg-5 px-md-5 px-sm-0 mb-4">Jangan ragu, YUMA siap membantu dan
                    menjawab pertanyaan anda
                    melalui kontak resmi atau formulir dibawah ini.</p>
            </div>
            <div class="row m-0">
                <div class="col-md-4 mb-2 p-0 d-flex justify-content-center">
                    <a type="button" class="btn btn-outline-primary d-flex align-items-center justify-content-center"
                        style="width: 346px;height: 127px;padding: 20px 10px 10px 10px;" @click="openGmail()">
                        <div>
                            <i class="fas fa-envelope mb-2 fs-2"></i>
                            <p>admin@yuma.or.id</p>
                        </div>
                    </a>
                </div>
                <div class="col-md-4 mb-2 p-0 d-flex justify-content-center">
                    <a type="button" class="btn btn-outline-primary d-flex align-items-center justify-content-center"
                        style="width: 346px;height: 127px;padding: 20px 10px 10px 10px;"
                        href="https://www.instagram.com/yuma_or.id/">
                        <div>
                            <i class="fab fa-instagram-square pe-1 fs-2"></i>
                            <p>yayasanurupmuliaananda</p>
                        </div>
                    </a>
                </div>
                <div class="col-md-4 mb-2 p-0 d-flex justify-content-center">
                    <a type="button" class="btn btn-outline-primary d-flex align-items-center justify-content-center"
                        style="width: 346px;height: 127px;padding: 20px 10px 10px 10px;" href="https://www.youtube.com/channel/UCAIElAoleDip_Te8hC-kePw">
                        <div>
                            <i class="fab fa-youtube pe-1 fs-2"></i>
                            <p>YUMA Official</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </header>
    <section class="pt-4" style="background-color: #9C5CE5; padding-bottom: 150px">
        <div class="px-5 py-4 py-xl-5">
            <div class="text-center mb-5">
                <h4 class="text-white">Hubungi Kami</h4>
            </div>
            <div class="card rounded-4">
                <div class="card-body p-5">
                    <form role="form" ref="formSubmit" @submit.prevent="confirmation()" novalidate>
                        <div class="row d-flex align-items-center">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Nama</label>
                                    <input type="text" class="form-control py-3" id="exampleFormControlInput1"
                                        placeholder="Masukkan Nama" v-model="nama" required>
                                </div>
                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Email</label>
                                    <input type="email" class="form-control py-3" id="exampleFormControlInput1"
                                        placeholder="Masukkan Email" v-model="email" required>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Pesan</label>
                                    <textarea class="form-control" id="exampleFormControlTextarea1"
                                        placeholder="Masukkan Pesan Anda" rows="6" v-model="pesan" required></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="text-end">
                            <button type="submit" class="btn text-white" style="background-color: #371065;">Kirim</button>
                        </div>

                        <!-- Modal -->
                        <div class="modal fade" id="confirmation" tabindex="0" aria-labelledby="confirmation"
                            aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <!-- <div class="modal-header">
                            <h6 class="modal-title">Apakah Anda Yakin ?</h6>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div> -->
                                    <div class="modal-body text-center">
                                        <i class="fa fa-exclamation text-danger text-gradient mb-3"
                                            style="font-size: 50px;"></i>
                                        <h6 class="modal-title">Apakah Anda Yakin ?</h6>
                                        <h4>Data yang diinput sudah benar</h4>
                                        <div class="d-flex justify-content-center">
                                            <button type="button" class="btn btn-sm btn-outline-primary me-3"
                                                data-bs-dismiss="modal">Kembali</button>
                                            <button @click="formSubmit()" class="btn btn-sm btn-primary"
                                                :disabled="isLoading">
                                                <span class="text-white">Benar</span>
                                                <span v-show="isLoading" class="spinner-border spinner-border-sm ms-2"
                                                    role="status" aria-hidden="true"></span>
                                            </button>
                                        </div>
                                    </div>
                                    <!-- <div class="modal-footer justify-content-center">
                            <button type="button" class="btn btn-sm btn-outline-primary"
                                data-bs-dismiss="modal">Kembali</button>
                            <button type="submit" class="btn btn-sm btn-primary">Benar
                            </button>
                        </div> -->
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <div class="mx-5 px-0 px-lg-5 px-md-5 px-sm-0" style=" border: none;margin-top: -100px;">
        <div class="card rounded-2 mb-5 shadow" style="border: none;">
            <div class="card-body p-5">
                <div class="text-center mb-5">
                    <h2 class="fw-bold my-4">Pertanyaan yang sering ditanyakan</h2>
                </div>
                <div class="accordion accordion-flush" id="accordionFlushExample">
                    <div class="accordion-item shadow mb-3">
                        <h2 class="accordion-header" id="flush-headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                Apa itu YUMA?
                            </button>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body text-muted">Yayasan urup Mulia Ananda 'YUMA' merupakan yayasan kanker
                                anak yang
                                memiliki tujuan untuk membantu mewujudkan impian dan harapan anak-anak pejuang penyakit
                                kanker.</div>
                        </div>
                    </div>
                    <div class="accordion-item shadow mb-3">
                        <h2 class="accordion-header" id="flush-headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                Berapa umur maksimal untuk bisa mendaftar di YUMA?
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body text-muted">Anak penderita kanker minimal usia 3 tahun dan maksimal
                                16 tahun
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow mb-3">
                        <h2 class="accordion-header" id="flush-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThree" aria-expanded="false"
                                aria-controls="flush-collapseThree">
                                Apakah semua jenis kanker bisa mendaftar di YUMA?
                            </button>
                        </h2>
                        <div id="flush-collapseThree" class="accordion-collapse collapse"
                            aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body text-muted">Ya, di YUMA semua anak penderita kanker bisa mendaftar
                                dengan
                                melengkapi persyaratan dan data diri sesuai panduan</div>
                        </div>
                    </div>
                    <div class="accordion-item shadow mb-3">
                        <h2 class="accordion-header" id="flush-headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFour" aria-expanded="false"
                                aria-controls="flush-collapseFour">
                                Apakah di YUMA anak penderita kanker bisa meminta bantuan dalam bentuk uang?
                            </button>
                        </h2>
                        <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body text-muted">Tidak, namun di YUMA anak penderita kanker bisa
                                mengajukan harapannya seperti membeli mainan baru, pakaian baru, pergi berlibur, bermain di
                                taman wisata atau pantai, membeli peralatan sekolah, alat bantu kesehatan, bertemu idola
                                favorit atau kamu mempunyai keinginan lain? Impian dan harapan kamu yang terpendam mungkin
                                bisa YUMA bantu wujudkan! Kamu bisa tulis impian dan harapan kamu di kolom keinginan ketika
                                mendaftar di YUMA!</div>
                        </div>
                    </div>
                    <div class="accordion-item shadow mb-3">
                        <h2 class="accordion-header" id="flush-headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFive" aria-expanded="false"
                                aria-controls="flush-collapseFive">
                                Apakah pemberi rekomendasi harus mengisi data orang tua dan non orang tua?
                            </button>
                        </h2>
                        <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body text-muted">Tidak, kamu bisa memilih salah satunya sebagai pemberi
                                rekomendasi
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import axios from '@/store/axios';
import bootstrap from '@/vendor/js/bootstrap.js'; // Import Bootstrap JavaScript

export default {
    name: 'HarapanView',
    components: {
    },
    data() {
        return {
            nama: '',
            email: '',
            pesan: '',

            isLoading: false,
        }
    },
    methods: {
        goToPageRegRek() {
            this.$router.push('/registrasiRek');
            window.scrollTo(0, 0);
        },
        goToPageRegNon() {
            this.$router.push('/registrasiNonRek');
            window.scrollTo(0, 0);
        },
        confirmation() {
            if (this.$refs.formSubmit.checkValidity()) {
                const myModal = bootstrap.Modal.getOrCreateInstance("#confirmation")
                myModal.show();
            } else {
                this.$refs.formSubmit.classList.add('was-validated')
            }
        },
        openGmail() {
            // Replace these variables with the recipient's email and subject
            const recipientEmail = 'admin@yuma.or.id';
            const subject = 'Pesan dan Saran';

            // Compose the mailto link
            const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}`;

            // Open the link in a new tab/window
            window.open(mailtoLink, '_blank');
        },
        formSubmit() {
            this.isLoading = true;
            var formDatas = new FormData();
            formDatas.append('nama', this.nama);
            formDatas.append('email', this.email);
            formDatas.append('pesan', this.pesan);

            // Axios post request with headers and form data
            axios.post('api/send_mail_', formDatas)
                .then(() => {
                    const myModal = bootstrap.Modal.getOrCreateInstance("#confirmation")
                    this.isLoading = false
                    this.nama = ''
                    this.email = ''
                    this.pesan = ''
                    //scroll up
                    this.$SwalCenter('success', 'Data Berhasil Disimpan', 'Silahkan Menunggu Beberapa Saat untuk proses validasi data')
                    this.$refs.formSubmit.classList.remove('was-validated')
                    myModal.hide();
                })
                .catch(() => {
                    const myModal = bootstrap.Modal.getOrCreateInstance("#confirmation")
                    this.$SwalCenter('error', 'Data Gagal Disimpan', 'Silahkan Menunggu Beberapa Saat, atau Cek Kembali Apakah Form sudah terisi dengan benar.')
                    myModal.hide();
                    this.isLoading = false
                });
        },
    }
}
</script>
    