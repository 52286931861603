<template>
    <nav id="mainNav" class="navbar navbar-light navbar-expand-md sticky-top navbar-shrink py-3"
        style="background-color: #ffffff;">
        <div class="container"><a class="navbar-brand d-flex align-items-center" href="/"><img
                    :src="require('@/assets/img/brands/clipboard-image.png')" alt="Clipboard Image"
                    width="180" /></a><button class="navbar-toggler" data-bs-toggle="collapse"
                data-bs-target="#navcol-1"><span class="visually-hidden">Toggle
                    navigation</span><span class="navbar-toggler-icon"></span></button>
            <div id="navcol-1" class="collapse navbar-collapse">
                <ul class="navbar-nav mx-auto">
                    <li class="nav-item"><a class="nav-link mx-1" :class="getRoute() === '' ? 'active' : ''" href="#"
                            @click="$router.push('/')" :data-bs-toggle="isMobile ? 'collapse' : null"
                            :data-bs-target="isMobile ? '#navcol-1' : null">Beranda</a></li>
                    <li class="nav-item"><a class="nav-link mx-1" :class="getRoute() === 'profil' ? 'active' : ''" href="#"
                            @click="$router.push('/profil')" :data-bs-toggle="isMobile ? 'collapse' : null"
                            :data-bs-target="isMobile ? '#navcol-1' : null">Profil</a>
                    </li>
                    <li class="nav-item"><a class="nav-link mx-1" :class="getRoute() === 'harapanku' ? 'active' : ''"
                            href="#" @click="$router.push('/harapanku')" :data-bs-toggle="isMobile ? 'collapse' : null"
                            :data-bs-target="isMobile ? '#navcol-1' : null">Harapanku</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle mx-1" href="#" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false"
                            :style="getRoute() === 'kegiatanSosial' || getRoute() === 'harapankuJadiNyata' ? { color: '#802fde' } : {}">
                            Aktivitas
                        </a>
                        <ul class="dropdown-menu animate slideIn text-center">
                            <li><a class="dropdown-item" :class="getRoute() === 'harapankuJadiNyata' ? 'active' : ''"
                                    href="#" @click="$router.push('/harapankuJadiNyata')"
                                    :data-bs-toggle="isMobile ? 'collapse' : null"
                                    :data-bs-target="isMobile ? '#navcol-1' : null">Harapanku
                                    Jadi Nyata</a></li>
                            <li><a class="dropdown-item" :class="getRoute() === 'kegiatanSosial' ? 'active' : ''" href="#"
                                    @click="$router.push('/kegiatanSosial')" :data-bs-toggle="isMobile ? 'collapse' : null"
                                    :data-bs-target="isMobile ? '#navcol-1' : null">Kegiatan
                                    Sosial</a></li>
                        </ul>
                    </li>
                    <li class="nav-item"><a class="nav-link mx-1" :class="getRoute() === 'pendaftaran' ? 'active' : ''"
                            href="#" @click="$router.push('/pendaftaran')" :data-bs-toggle="isMobile ? 'collapse' : null"
                            :data-bs-target="isMobile ? '#navcol-1' : null">Pendaftaran</a></li>
                    <li class="nav-item"><a class="nav-link mx-1" :class="getRoute() === 'hubungiKami' ? 'active' : ''"
                            href="#" @click="$router.push('/hubungiKami')" :data-bs-toggle="isMobile ? 'collapse' : null"
                            :data-bs-target="isMobile ? '#navcol-1' : null">Hubungi Kami</a></li>
                </ul>
                <!-- <a class="btn btn-primary d-flex justify-content-center rounded-3 px-4 py-2" role="button"
                    style="border: none;" @click="$router.push('/donasi')" :data-bs-toggle="isMobile ? 'collapse' : null"
                    :data-bs-target="isMobile ? '#navcol-1' : null">Donasi<img class="ms-2"
                        :src="require('@/assets/img/brands/clipboard-image-1.png')" style="vertical-align: middle;"
                        width="22" height="20" /></a> -->
            </div>
        </div>
    </nav>
</template>
<script>
export default {
    data() {
        return {
            isMobile: false
        };
    },
    mounted() {
        // Check initial screen width
        this.checkMobileWidth();
        // Add a window resize event listener to update the style dynamically
        window.addEventListener('resize', this.checkMobileWidth);
    },
    beforeUnmount() {
        // Remove the window resize event listener when the component is destroyed
        window.removeEventListener('resize', this.checkMobileWidth);
    },
    methods: {
        checkMobileWidth() {
            // Update the 'isMobile' data property based on the screen width
            this.isMobile = window.innerWidth <= 767;
        },
        getRoute() {
            const routeArr = this.$route.path.split("/");
            return routeArr[1];
        },
        ScrollUp() {
            window.scrollTo(0, 0);
        }
    }
}
</script>