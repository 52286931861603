<template>
    <section class="py-5">
        <div class="px-5 py-4 py-xl-5">
            <div class="row gy-4 gy-md-0 align-items-center p-0 p-lg-5 p-md-5 p-sm-0">
                <div
                    class="col-md-7 text-start text-md-start d-flex justify-content-center align-items-center justify-content-md-start align-items-md-center justify-content-xl-start">
                    <div class="justify" :class="{ 'w-75': !isMobile }">
                        <h1 class="fw-bolder mb-4" style="color: #371065; font-size: 36px;">Kegiatan Sosial
                        </h1>
                        <p class="fw-lighter mb-4 pe-lg-5 pe-md-5 pe-sm-0 text-muted">
                            Beragam kegiatan sosial dilakukan di YUMA, diantaranya memberikan kontribusi aktif dan nyata,
                            tidak hanya untuk anak-anak penderita kanker namun juga kepada masyarakat luas. Webinar
                            kesehatan tentang kank-er anak, kunjungan ke rumah singgah, hingga bermain bersama para anak
                            penderita kanker juga dilakukan YUMA, serta masih banyak kegiatan lainnya. Yuk lihat
                            keseruannya!
                        </p>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="text-center d-flex align-items-center justify-content-center">
                        <img :src="require('@/assets/img/illustrations/splashimg3.png')" :width="[isMobile ? '300' : '480']"
                            style="border-radius: 80px 30px" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="py-5 px-5 mb-5">
        <div class="gy-4 gy-md-0p-0 px-lg-5 px-md-5 px-sm-0">
            <div class="d-flex align-items-center justify-content-between mb-3">
                <h2 class="text-start fw-bold mb-4">Kegiatan Sosial</h2>
                <div class="d-flex">
                    <button class="btn btn-white btn-circle d-flex align-items-center justify-content-center me-1"
                        @click="scrollToLeft">
                        <i class="fas fa-chevron-left fs-5"></i>
                    </button>
                    <button class="btn btn-white btn-circle d-flex align-items-center justify-content-center"
                        @click="scrollToRight">
                        <i class="fas fa-chevron-right fs-5"></i>
                    </button>
                </div>
            </div>
            <div class="scrollable-div px-2 d-flex align-items-center" ref="scrollableDiv" style="height: 550px;">
                <div class="" style="display: inline-flex; align-items: flex-start; gap: 20px;" ref="scrollDiv">
                    <div v-for="(data) in harapanku_jn" :key="data.id" class="card"
                        style="width: 290px; height: 504px; border: 0px !important;">
                        <div class="card-body">
                            <div class=" p-2 text-end"
                                style="height: 180px; background-size: cover; background-position: center; border-radius: 50px 10px;"
                                :style="data.img">
                                <span class="badge px-3 py-2" style="background-color: #0057B8;">{{ data.date }}</span>
                            </div>
                            <h5 class="card-title mt-4 fw-bold" style="height: 100px;">{{ data.title }}</h5>
                            <p class="card-text text-muted" style=" height: 120px;">{{ data.detail }}</p>
                            <router-link :to="'/kegiatanSosial/detailKegiatanSosial/' + data.id"
                                class="d-flex justify-content-between align-items-center">
                                <span>Baca Selengkapnya</span>
                                <i class="fas fa-arrow-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: 'ProfilView',
    data() {
        return {
            harapanku_jn: [
                
            ],
            screenWidth: window.innerWidth, // Initialize with the current screen width
            isMobile: false
        }
    },
    components: {
    },
    mounted() {
        if (this.screenWidth <= 610) {
            this.isMobile = true
        } else {
            this.isMobile = false
        }

        // Attach a window resize event listener to update the screenWidth data property
        window.addEventListener('resize', this.handleResize);
    },
    watch: {
        screenWidth(newValue) {
            if (newValue <= 610) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        }
    },
    methods: {
        scrollToRight() {
            const scrollableDiv = this.$refs.scrollableDiv;
            scrollableDiv.scrollBy({ left: 300, behavior: 'smooth' }); // Adjust the scroll value as needed
        },
        scrollToLeft() {
            const scrollableDiv = this.$refs.scrollableDiv;
            scrollableDiv.scrollBy({ left: -300, behavior: 'smooth' }); // Adjust the scroll value as needed
        },
        handleResize() {
            // Update the screenWidth data property when the window is resized
            this.screenWidth = window.innerWidth;
        }
    },
    unmounted() {
        // Remove the window resize event listener when the component is destroyed
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style scoped>
.btn-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 14px;
    color: #802fde;
}

.btn-circle:hover {
    color: white;
    background-color: #802fde;
}

.scrollable-div {
    overflow-x: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
}

/* Hide the scrollbar for modern browsers */
.scrollable-div::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.card:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    /* Apply a shadow when hovering */
}
</style>