<template>
    <header>
        <div class="px-5 py-4 py-xl-5 fit-img text-center"
            :style="{ 'background-image': 'url(' + require('@/assets/img/illustrations/splashimg2.png') + ')' }">
            <h1 class="fw-bold mb-4 text-white">Persyaratan Pendaftaran Penerima Manfaat YUMA</h1>
            <p class="text-white text-center px-lg-5 px-md-5 px-sm-0 mb-4">Registrasi -> Melengkapi Formulir Data Diri ->
                Proses Validasi data oleh YUMA -> Selesai</p>
        </div>
    </header>
    <section class="py-5">
        <div class="px-5 py-4 py-xl-5">
            <div class="row gy-4 gy-md-0 align-items-center">
                <div class="col-md-7 justify ms-auto order-1 order-sm-1 order-md-0 order-xl-0">
                    <h2 class="text-start fw-bold mb-4">Kriteria Penerima Manfaat <span class="text-primary">YUMA</span>
                    </h2>
                    <div class="row d-flex align-items-center my-2">
                        <div class="col-md-1 text-center my-1">
                            <span class="badge rounded-circle text-light bg-white border border-2 border-primary p-2">
                                <p class="text-primary fs-4 m-0" style="padding: 0px 8px;">1</p>
                            </span>
                        </div>
                        <div class="col-md-11 my-1">
                            <p class="fw-lighter m-0">Berusia minimal 3 tahun dan maksimal 16 tahun.</p>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center my-2">
                        <div class="col-md-1 text-center my-1">
                            <span class="badge rounded-circle text-light bg-white border border-2 border-primary p-2">
                                <p class="text-primary fs-4 m-0 " style="padding: 0px 5px;">2</p>
                            </span>
                        </div>
                        <div class="col-md-11 my-1">
                            <p class="fw-lighter m-0">Terindikasi penyakit kanker, dibuktikan dengan surat keterangan dari
                                dokter rumah sakit yang menangani pasien.</p>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center my-2">
                        <div class="col-md-1 text-center my-1">
                            <span class="badge rounded-circle text-light bg-white border border-2 border-primary p-2">
                                <p class="text-primary fs-4 m-0 " style="padding: 0px 5px;">3</p>
                            </span>
                        </div>
                        <div class="col-md-11 my-1">
                            <p class="fw-lighter m-0">WNI dan berdomisili di Indonesia.</p>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center my-2">
                        <div class="col-md-1 text-center my-1">
                            <span class="badge rounded-circle text-light bg-white border border-2 border-primary p-2">
                                <p class="text-primary fs-4 m-0" style="padding: 0px 5px;">4</p>
                            </span>
                        </div>
                        <div class="col-md-11 my-1">
                            <p class="fw-lighter m-0">Berasal dari keluarga tidak mampu, dibuktikan dengan Surat
                                Keterangan Tidak Mampu (SKTM) setingkat desa/ kelurahan.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 order-0 order-sm-0 order-md-1 order-xl-1">
                    <div class="text-center text-lg-end text-md-end text-sm-center">
                        <img class="rounded img-fluid fit-cover" :src="require('@/assets/img/illustrations/img8.png')"
                            width="300" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="py-5">
        <div class="px-5 py-4 py-xl-5">
            <div class="row gy-4 gy-md-0 align-items-center">
                <div class="col-md-5">
                    <div class="text-center">
                        <img class="rounded img-fluid fit-cover" :src="require('@/assets/img/illustrations/img10.png')"
                            width="250" />
                    </div>
                </div>
                <div class="col-md-7 justify ms-auto">
                    <h2 class="text-start fw-bold mb-4">Syarat Mendaftar
                    </h2>
                    <div class="row d-flex align-items-center my-2">
                        <div class="col-md-1 text-center my-1">
                            <span class="badge rounded-circle text-light bg-white border border-2 border-primary p-2">
                                <p class="text-primary fs-4 m-0" style="padding: 0px 8px;">1</p>
                            </span>
                        </div>
                        <div class="col-md-11 my-1">
                            <p class="fw-lighter m-0">
                                Pemberi rekomendasi merupakan orang tua/ wali atau non orang tua (Tenaga Medis, saudara,
                                kolega, dan lainnya yang mengetahui kondisi anak penderita kanker).</p>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center my-2">
                        <div class="col-md-1 text-center my-1">
                            <span class="badge rounded-circle text-light bg-white border border-2 border-primary p-2">
                                <p class="text-primary fs-4 m-0 " style="padding: 0px 5px;">2</p>
                            </span>
                        </div>
                        <div class="col-md-11 my-1">
                            <p class="fw-lighter m-0">Surat persetujuan orang tua/ wali (Bagi pemberi rekomendasi non orang
                                tua).</p>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center my-2">
                        <div class="col-md-1 text-center my-1">
                            <span class="badge rounded-circle text-light bg-white border border-2 border-primary p-2">
                                <p class="text-primary fs-4 m-0 " style="padding: 0px 5px;">3</p>
                            </span>
                        </div>
                        <div class="col-md-11 my-1">
                            <p class="fw-lighter m-0">Mengisi data diri lengkap anak penderita kanker beserta pemberi
                                rekomendasi.</p>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center my-2">
                        <div class="col-md-1 text-center my-1">
                            <span class="badge rounded-circle text-light bg-white border border-2 border-primary p-2">
                                <p class="text-primary fs-4 m-0" style="padding: 0px 5px;">4</p>
                            </span>
                        </div>
                        <div class="col-md-11 my-1">
                            <p class="fw-lighter m-0">Surat keterangan yang menyatakan kondisi anak penderita kanker dari
                                dokter atau rumah sakit tempat anak menjalani perawatan.</p>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center my-2">
                        <div class="col-md-1 text-center my-1">
                            <span class="badge rounded-circle text-light bg-white border border-2 border-primary p-2">
                                <p class="text-primary fs-4 m-0 " style="padding: 0px 5px;">5</p>
                            </span>
                        </div>
                        <div class="col-md-11 my-1">
                            <p class="fw-lighter m-0">Menyertakan dokumen yang diminta.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="py-5">
        <div class="px-5 py-4 py-xl-5">
            <div class="row gy-4 gy-md-0 align-items-center">
                <div class="col-md-7 justify ms-auto order-1 order-sm-1 order-md-0 order-xl-0">
                    <h2 class="text-start fw-bold mb-4">Pemberi Rekomendasi
                    </h2>
                    <div class="row d-flex align-items-center my-2">
                        <div class="col-md-1 text-center my-1">
                            <span class="badge rounded-circle text-light bg-white border border-2 border-primary p-2">
                                <p class="text-primary fs-4 m-0" style="padding: 0px 8px;">1</p>
                            </span>
                        </div>
                        <div class="col-md-11 my-1">
                            <p class="fw-lighter m-0">Orang tua/ Wali.</p>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center my-2">
                        <div class="col-md-1 text-center my-1">
                            <span class="badge rounded-circle text-light bg-white border border-2 border-primary p-2">
                                <p class="text-primary fs-4 m-0 " style="padding: 0px 5px;">2</p>
                            </span>
                        </div>
                        <div class="col-md-11 my-1">
                            <p class="fw-lighter m-0">Tenaga Medis (dokter, perawat, dan lainnya).</p>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center my-2">
                        <div class="col-md-1 text-center my-1">
                            <span class="badge rounded-circle text-light bg-white border border-2 border-primary p-2">
                                <p class="text-primary fs-4 m-0 " style="padding: 0px 5px;">3</p>
                            </span>
                        </div>
                        <div class="col-md-11 my-1">
                            <p class="fw-lighter m-0">Saudara kandung atau kolega yang mengetahui kondisi anak penderita
                                kanker.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 order-0 order-sm-0 order-md-1 order-xl-1">
                    <div class="text-center text-lg-end text-md-end text-sm-center">
                        <img class="rounded img-fluid fit-cover" :src="require('@/assets/img/illustrations/img9.png')"
                            width="200" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="py-5">
        <div class="px-5 py-4 py-xl-5">
            <div class="row gy-4 gy-md-0 align-items-center">
                <div class="col-md-5">
                    <div class="text-center">
                        <img class="rounded img-fluid fit-cover"
                            :src="require('@/assets/img/illustrations/candidate-resume.png')" width="250" />
                    </div>
                </div>
                <div class="col-md-7 justify ms-auto">
                    <h2 class="text-start fw-bold mb-4">Proses Validasi Data
                    </h2>
                    <div class="row d-flex align-items-center my-2">
                        <div class="col-md-1 text-center my-1">
                            <span class="badge rounded-circle text-light bg-white border border-2 border-primary p-2">
                                <p class="text-primary fs-4 m-0" style="padding: 0px 8px;">1</p>
                            </span>
                        </div>
                        <div class="col-md-11 my-1">
                            <p class="fw-lighter m-0">
                                Pemberi rekomendasi selesai melakukan pendaftaran dan mensubmit seluruh dokumen yang
                                diminta.</p>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center my-2">
                        <div class="col-md-1 text-center my-1">
                            <span class="badge rounded-circle text-light bg-white border border-2 border-primary p-2">
                                <p class="text-primary fs-4 m-0 " style="padding: 0px 5px;">2</p>
                            </span>
                        </div>
                        <div class="col-md-11 my-1">
                            <p class="fw-lighter m-0">Tim YUMA akan memvalidasi data calon penerima manfaat YUMA yang sudah
                                masuk.</p>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center my-2">
                        <div class="col-md-1 text-center my-1">
                            <span class="badge rounded-circle text-light bg-white border border-2 border-primary p-2">
                                <p class="text-primary fs-4 m-0 " style="padding: 0px 5px;">3</p>
                            </span>
                        </div>
                        <div class="col-md-11 my-1">
                            <p class="fw-lighter m-0 mb-1">Tim YUMA akan menghubungi calon penerima manfaat melalui nomor
                                Tlp/
                                WA/ email aktif yang dicantumkan saat melakukan pendaftaran.</p>
                            <p class="fw-bold m-0" style="font-size: small;">*Pastikan nomor Tlp/ WA/ email yang dicantumkan
                                saat melakukan
                                pendaftaran aktif</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="py-5 mb-5">
        <div class="px-5">
            <p>*Download template surat persetujuan orang tua <a class="text-dark fw-bold" href="/SuratPersetujuan.docx"
                    download><u>Disini</u></a></p>
        </div>
    </section>
    <section class="py-5 mb-5">
        <div class="d-flex align-items-center justify-content-center">
            <a type="button" class="btn btn-danger rounded-3 mx-2"
                style="font-size: 12px; font-weight: 700; padding: 10px 40px;" href="/registrasiRek">Registrasi
                Rekomendasi Orang Tua</a>
            <button type="button" class="btn btn-outline-danger rounded-3 mx-2"
                style="font-size: 12px; font-weight: 700; padding: 10px 40px;" @click="goToPageRegNon">Registrasi
                Rekomendasi Non Orang Tua</button>
        </div>
    </section>
</template>
    
<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: 'HarapanView',
    components: {
    },
    data() {
        return {
        };
    },
    methods: {
        goToPageRegRek() {
            this.$router.push('/registrasiRek');
            window.scrollTo(0, 0);
        },
        goToPageRegNon() {
            this.$router.push('/registrasiNonRek');
            window.scrollTo(0, 0);
        },
    }
}
</script>
    